import React, {useEffect, useState} from "react";
import {announcementService} from "../../../services/announcementService/announcementService";
import TextEditorContent from "../../../components/TextEditorContent";

const DashboardAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        getAnnouncements();
    }, []);

    const getAnnouncements = async () => {
        try {
            const response = await announcementService.getAnnouncements();

            setAnnouncements(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    if (announcements.length === 0) {
        return (
            <></>
        );
    }

    return (
        <>
            {announcements?.map((announcement) => {
                return (
                    <React.Fragment key={announcement?.id}>
                        <div className="dashboard-announcement-card">
                            <TextEditorContent
                                text={announcement?.message}
                                style={{
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem"
                                }}
                            />
                        </div>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default DashboardAnnouncements;