import React, {useEffect, useState} from "react";
import Loader from "../../components/common/Loader";
import avatarUser from "../../assets/profile-settings/avatarImage.jpg";
import "./Users.css";
import {useParams} from "react-router-dom";
import {userService} from "../../services/usersService/userService";
import useRenderTextEditorContent from "../../hooks/useTextEditorContent";
import AchievementsListCard from "../../components/Achievements/AchievementsListCard";
import {useDispatch, useSelector} from "react-redux";
import {setNewChatUserData} from "../../redux/messengerSlice";

const UserProfile = () => {
    const userData = useSelector((state) => state?.auth?.userData);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState(null);
    const { id } = useParams();
    const renderAnswer = useRenderTextEditorContent();

    const userDetailsData = async () => {
        setIsLoading(true);
        try {
            await userService.getSingleUserData(id).then((response) => {
                setUserDetails(response?.data);
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        userDetailsData();
    }, [id]);

    return (
        <>
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <div className="container m-4">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 text-center">
                                <div className="user-profile-info p-3 mt-3">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div className="d-flex flex-column mb-2">
                                            <div className="profile-image mb-1">
                                                <figure style={{position: "relative", overflow: "hidden"}}>
                                                    <img
                                                        crossOrigin="anonymous"
                                                        className="img-fluid object-fit-cover"
                                                        src={userDetails?.avatar || avatarUser}
                                                        alt="Profile Image"
                                                    />
                                                </figure>
                                            </div>
                                            <span className="fs-5 fw-bold">
                                                {userDetails?.name}
                                            </span>
                                            <span className="fs-6 fw-semibold text-secondary">
                                                {userDetails?.title}
                                            </span>
                                            <span className="fs-6 fw-normal text-secondary">
                                                {userDetails?.company}
                                            </span>
                                            {userDetails?.state && userDetails?.city &&
                                                <span className="fs-6 fw-normal text-secondary">
                                                    {`${userDetails?.state}, ${userDetails?.city}`}
                                                </span>
                                            }
                                        </div>
                                        <div className="mt-1">
                                            {
                                                userDetails?.bio && renderAnswer(userDetails?.bio)?.map((element, index) =>
                                                    <React.Fragment key={index}>{element}</React.Fragment>)
                                            }
                                        </div>
                                        <div className="mt-2">
                                            <div className="skills">
                                                {userDetails?.tags.map((tag) => (
                                                    <span className="skills__badge">
                                                        {tag.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        {userData?.id !== userDetails?.id &&
                                            <button
                                                className="btn btn-primary mt-2"
                                                onClick={() => dispatch(setNewChatUserData(userDetails))}
                                            >
                                                Message Member
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {userDetails?.badges.length > 0 &&
                            <div className="row mt-3">
                                <div className="col-lg-6 col-md-12">
                                    <AchievementsListCard achievements={userDetails?.badges}/>
                                </div>
                            </div>
                        }
                    </div>
                </>
            )}
        </>
    );
};

export default UserProfile;