import apiClient from "../../utils/apiClient";

const GET_TAG = "/tags";

const getTag = () => {
    const url = `${GET_TAG}`;

    return apiClient.get(url);
};

export const tagService = {
    getTag
};