import {useNavigate} from "react-router-dom";
import React from "react";
import CommentLoader from "../../../components/common/CommentLoader";
import {searchCategories} from "../../../utils/data/searchCategories";
import avatarUser from "../../../assets/profile-settings/avatarImage.jpg";

const CommunitySearch = ({ searchResult, isLoading, searchTerm, style, onSelectResultsItem }) => {

    const navigate = useNavigate();

    if (isLoading) {
        return <div className="community-search__results">
            <CommentLoader />
        </div>;
    }

    if (searchResult.length === 0 || !searchTerm) {
        return <></>;
    }

    const handleNavigate = (path) => {
        navigate(path);
        if (onSelectResultsItem) {
            onSelectResultsItem();
        }
    };

    return (
        <div className="community-search__results" style={style}>
            {Object.keys(searchResult).map((categoryKey) => {

                const results = searchResult[categoryKey];
                const {title, path, namingField, type} = searchCategories[categoryKey] || {};

                return results.length > 0 ? (
                    <>
                        <div className="fs-5 fw-bold py-1">
                            {title}:
                        </div>
                        {results.map((result) => {
                            switch (type) {
                                case 'users':
                                    return <div key={result.id} className="search-result" onClick={() => handleNavigate(path + result.id)}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            {result?.avatar ? (
                                                <img crossOrigin="anonymous" className="user-details__profile-image"
                                                     src={result?.avatar} alt="avatar"/>
                                            ) : (
                                                <img crossOrigin="anonymous" className="user-details__profile-image"
                                                     src={avatarUser} alt="default avatar"/>
                                            )}
                                            <div className="fs-6 fw-normal search-result__item">
                                                {result.name}
                                            </div>
                                        </div>
                                    </div>;
                                default:
                                    return <div key={result.id} className="search-result" onClick={() => handleNavigate(path + result.id)}>
                                        <div className="fs-6 fw-normal search-result__item">
                                            {result[namingField]}
                                        </div>
                                    </div>;
                            }
                        })}
                    </>
                ) : null;
            })}
        </div>
    );
};
export default CommunitySearch;