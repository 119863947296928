import {ArrowLeftIcon, ArrowRightIcon} from "@mui/x-date-pickers";
import "./Pagination.css";

function pagination(current, last) {
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

export const Pagination = ({ currentPage, totalPages, onPageChange, theme = 'white' }) => {

    if (totalPages === 1) {
        return <></>;
    }

    const pages = pagination(currentPage, totalPages).map((page, index) => (
        <div
            key={index}
            className={`text-sm-medium pagination__pages__page ${page === currentPage ? `active-${theme}` : ''}`}
            onClick={() => onPageChange(page)}
        >
            {page}
        </div>
    ));

    return (
        <div className={`pagination ${theme} shadow-sm`}>
            {currentPage > 1 ?
                <div className={`pagination__scroll-button ${theme}`} onClick={() => onPageChange(currentPage - 1)}>
                    <ArrowLeftIcon />
                    <div className="pagination__scroll-button__text text-sm-semibold">Previous</div>
                </div> : <div></div>
            }
            <div className="pagination__pages">
                {pages}
            </div>
            {currentPage !== totalPages ?
                <div className={`pagination__scroll-button ${theme}`} onClick={() => onPageChange(currentPage + 1)}>
                    <div
                        className="pagination__scroll-button__text pagination__scroll-button__first text-sm-semibold">Next
                    </div>
                    <ArrowRightIcon />
                </div> : <div></div>
            }
        </div>
    );
};