import React from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LiveEvent from "../../../assets/dashboard/live.png";
import { MEETING_TYPES, ROUTES } from "../../../constants";
import imageNotFound from "../../../assets/Logo/catapult_logo.png";

const UnlockedSpecialEvents = ({ events }) => {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleEventJoin = (id, alternativeUrl) => {
        if (alternativeUrl) {
            window.open(alternativeUrl, '_blank');
        }
        if (!alternativeUrl) {
            navigate("/live-events/" + id);
            scrollToTop();
        }
    };

    return (
        <>
            {events?.length > 0 && (
                <>
                    <div className="d-flex gap-2 align-items-center">
                        <h5 className="m-0">Live Event </h5>
                        <img src={LiveEvent} alt="live event" className="live-image blink-hard" />
                    </div>
                    <Row className="mt-3">
                        {events.map((event, index) => (
                            <Col key={index} className="mb-4">
                                <Card className="m-2 text-white special-event-card-body">
                                    <div className="text-center">
                                        {event?.full_cover_image === null ? (
                                            <Card.Img variant="top" src={imageNotFound} alt={event?.title} className="special-upcoming-dummy-event-image" />
                                        ) : (
                                            <Card.Img variant="top" src={event?.full_cover_image} alt={event?.title} className="special-upcoming-event-image" />
                                        )}
                                    </div>

                                    <Card.Body className="special-event-card-body d-flex justify-content-between align-items-center">
                                        <Card.Title className="m-0">{event?.title}</Card.Title>
                                        <Button variant="primary" onClick={() => handleEventJoin(event.id, event.alternative_meeting_url)}>
                                            Join Event
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default UnlockedSpecialEvents;
