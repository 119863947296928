/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./dashboard.css";
import {useSelector} from "react-redux";
import InstitutionUserDashboard from "./components/InstitutionUserDashboard";
import SpeakerDashboard from "./components/SpeakerDashboard";

const Dashboard = () => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);

    if (userType === 4) {
        return <SpeakerDashboard />
    }

    return <InstitutionUserDashboard />;
};

export default Dashboard;
