import apiClient from "../../utils/apiClient";

const getComments = (path, cursor) => {
    const url = `${path}/comments${cursor ? `?cursor=${cursor}` : ''}`;
    return apiClient.get(url);
};

const addComment = (path, data) => {
    const url = `${path}/comments`;
    return apiClient.post(url, data);
};

const updateComment = (path, data, commentId) => {
    const url = `${path}/comments/${commentId}`;
    return apiClient.put(url, data);
};

const deleteComment = (path, commentId) => {
    const url = `${path}/comments/${commentId}`;
    return apiClient.delete(url);
};

const likeComment = (path, commentId) => {
    const url = `${path}/comments/${commentId}/like`;
    return apiClient.post(url);
};

export const CommentsService = {
    getComments,
    addComment,
    likeComment,
    updateComment,
    deleteComment
};