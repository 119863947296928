import { useMemo } from 'react';

const useRenderTextEditorContent = () => {
    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                // Remove HTML tags from the text
                const textWithoutTags = part.replace(/(<([^>]+)>)/gi, "");
                return <span key={index}>{textWithoutTags}</span>;
            }
        });
    };

    return useMemo(() => renderAnswer, []);
};

export default useRenderTextEditorContent;