/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MEETING_TYPES, ROUTES } from "../../../../constants";
import Logo from "../../../../assets/catapult-logo.svg";
import { PiLockSimpleLight } from "react-icons/pi";
import moment from "moment";
import { MdOutlineRateReview } from "react-icons/md";

const UnlockedSessionCard = ({ session, setIsPreviousSession, flag }) => {
    const masterClass = localStorage.getItem("masterClass");
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleSession = (event) => {
        event.stopPropagation();
        if (flag) {
            setIsPreviousSession(true);
            navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.previousSession).replace(":id", session.key_note_id));
        }
        scrollToTop();
    };

    const handleFeedback = (event) => {
        event.stopPropagation();
        if (masterClass === "Career Readiness") {
            window.open(" https://survey.alchemer.com/s3/7708768/Career-Readiness-Masterclass-Series-Presentation-Feedback", "_blank");
        } else {
            window.open("https://catapult.fillout.com/post-keynotesurvey", "_blank");
        }
    };

    return (
        <div className="position-relative" style={{ cursor: flag ? "pointer" : "not-allowed" }} onClick={handleSession}>
            <div className={`row inner-card-layout-unlocked-session  py-3 px-2 mt-4 justify-content-start align-items-start ${!flag ? "inner-card-layout-locked-session" : ""}`}>
                <img crossOrigin="anonymous" src={session?.key_note?.full_cover_image} alt="" className="col-lg-5 col-md-5 col-sm-12 col-xs-12 session-cover-img" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 session-right-content d-flex flex-column justify-content-start align-items-start">
                    <img crossOrigin="anonymous" src={Logo} alt="" />
                    <div className="ms-2">
                        <div className={`session-masterclass-name mt-2 ${!session?.key_note?.master_class?.name?.includes(" ") && "no-space-text"}`}>{session?.key_note?.master_class?.name}</div>
                        <div className={`session-keynote-name mb-3${!session?.key_note?.name?.includes(" ") && "no-space-text"}`}>{session?.key_note?.name}</div>
                        <div className="d-flex gap-2">
                            <button className="watch-session-btn mt-3" onClick={handleSession}>
                                <FiArrowUpRight className="fs-5 me-2" />
                                Watch Now
                            </button>
                            <button className="feedback-session-btn mt-3" onClick={handleFeedback}>
                                <MdOutlineRateReview className="fs-5 me-2" />
                                Feedback
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {!flag && (
                <div className="lock-overlay" style={{ cursor: flag ? "pointer" : "not-allowed" }}>
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                        <PiLockSimpleLight className="fs-1" />
                        <div>
                            <div className="session-lock-subtext">Course will be available:</div>
                            <div className="session-lock-date">{moment(session?.start_date).format("Do MMM YYYY")}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UnlockedSessionCard;
