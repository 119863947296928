import React, {useEffect, useState} from "react";
import {FiSearch} from "react-icons/fi";
import useDebounce from "../../../hooks/useDebounce";
import {communityService} from "../../../services/communityService/communityService";
import CommunitySearchResults from "./CommunitySearchResults";

const CommunitySearch = ({  }) => {

    const [searchResult, setSearchResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearchTerm = useDebounce(searchTerm, 750);

    useEffect( () => {
        if (searchTerm) {
            getSearchResults();
        }
    }, [debouncedSearchTerm]);

    const getSearchResults = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.communitySearch(searchTerm);
            setSearchResult(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="community-search">
            <div className="community__main-title__search-container mt-3">
                <input onChange={handleSearch} placeholder="Search"
                       className="community__main-title__search-container__search-input"/>
                <FiSearch className="community__main-title__search-container__search-icon"/>
            </div>
            <CommunitySearchResults
                searchResult={searchResult}
                isLoading={isLoading}
                searchTerm={searchTerm}
            />
        </div>
    );
};
export default CommunitySearch;