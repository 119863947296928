import React from "react";
import {useSelector} from "react-redux";
import SpeakerMasterclasses from "./components/SpeakerMasterclasses";
import InstitutionUserMasterclasses from "./components/InstitutionUserMasterclasses";

const MasterClasses = () => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);

    if (userType === 4) {
        return <SpeakerMasterclasses />
    }

    return <InstitutionUserMasterclasses />;
};

export default MasterClasses;