import React, {useEffect, useState} from "react";
import {CommentsService} from "../../services/commentsService/commentsService";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import CatapultLogo from "../../assets/catapult_mdl.png";
import ModalClose from "../../assets/modal_close.png";

const EditCommentModal = ({ show, comment, handleClosePopup, path, onUpdateComment }) => {
    const [commentBody, setCommentBody] = useState(comment?.body || "");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        setCommentBody(comment?.body || "");
        setIsButtonDisabled(true);
    }, [comment]);

    const handleClose = () => {
        setCommentBody("");
        handleClosePopup();
    };

    const handleCommentChange = (e) => {
        setCommentBody(e.target.value);
        setIsButtonDisabled(false);
    };
    const updateCommentData = async () => {
        setIsButtonDisabled(true);

        const data = {
            body: commentBody,
        };

        try {
            const response = await CommentsService.updateComment(path, data, comment.id);
            const updatedComment = response.data;

            toast.success("Your comment has been updated successfully");

            if (onUpdateComment) {
                onUpdateComment(updatedComment);
            }

            handleClose();
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to update comment. Please try again.");
        }
    };

    if (!show) {
        return <></>;
    }

    return (
        <>
            <Modal show={true} onHide={handleClose} className="comment-reply-popup modal-lg">
                <Modal.Header>
                    <img src={CatapultLogo}></img>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose}></img>
                    </div>
                </Modal.Header>
                <Modal.Footer className="my-3 mx-3">
                    <Button variant="primary" onClick={updateCommentData} disabled={isButtonDisabled}>
                        Save
                    </Button>
                </Modal.Footer>

                <Modal.Body className="mx-3 my-3">
                    <div className="comment__message-form-reply">
                            <textarea
                                value={commentBody}
                                onChange={handleCommentChange}
                                placeholder="Write a comment..."
                                className="w-ful py-2"
                                rows={4}>
                            </textarea>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditCommentModal;