import {useNavigate} from "react-router-dom";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";

const ProgramCard = ({ program }) => {
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleNavigate = (event) => {
        event.stopPropagation();
        navigate("/programs/" + program?.id);
        scrollToTop();
    };

    return (
        <>
            <div
                className={`text-white card px-5 py-4 cursor-pointer program-masterclass-card rounded-3 ${
                    program?.is_completed ? "overlay-completed program-completed-opacity" : ""
                }`}
                 onClick={handleNavigate}>
                <div className="row no-gutters">
                    <div className="col-md-4 d-flex align-items-center">
                        <img src={program?.cover_image} className="card-img master-class-lesson__card-thumbnail img-fluid rounded-3" alt={program?.name}/>
                    </div>
                    <div className="col-md-8">
                        <div className="master-class-lesson__card__content">
                            <div className="mt-3">
                                <span className="fs-4 lh-base fw-bolder">
                                    {program?.name}
                                </span>
                            </div>
                            <div className="program-card-description mt-3">
                                {
                                    program?.description && renderAnswer(program?.description)?.map((element, index) =>
                                        <React.Fragment key={index}>{element}</React.Fragment>)
                                }
                            </div>
                            <div className="mt-3">
                                <span className="fs-6 lh-base fw-bolder">
                                    Credit Hours: {program?.credit_hours}
                                </span>
                            </div>
                            <div className="mt-3">
                                <ProgressBar
                                    completed={program?.progress}
                                    bgColor="#1c64f2"
                                    baseBgColor="#374151"
                                    className="w-75 progressbar-count"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgramCard;