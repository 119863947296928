import {useNavigate} from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";

const ProgramMasterclassCard = ({ masterClass }) => {
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();

    const handleNavigate = () => {
        navigate("/master-classes/" + masterClass.id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 col-12">
                <div className={`card text-white m-3 p-4 program-masterclass-card rounded-3 ${
                    masterClass?.is_completed ? "overlay-completed master-class-completed-opacity" : ""
                }`} onClick={handleNavigate}>
                    <img src={masterClass?.cover_image} className="card-img-top program-masterclass-card-thumbnail img-fluid rounded-3 mb-3"
                         alt={masterClass?.name}/>
                    <h5 className="card-title mb-3">{masterClass?.name}</h5>
                    <div className="program-masterclass-card-description mb-3">
                        {
                            masterClass?.description && renderAnswer(masterClass?.description)?.map((element, index) =>
                                <React.Fragment key={index}>{element}</React.Fragment>)
                        }
                    </div>
                    <ProgressBar completed={masterClass.progress} bgColor="#1c64f2" baseBgColor="#374151"
                                 className={`w-100 progressbar-count mt-2 ${masterClass.progress > 5 ? '' : 'progressbar-count__hide-value'}`}/>
                </div>
            </div>
        </>
    );
};

export default ProgramMasterclassCard;