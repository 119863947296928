import React from "react";
import "../masterClasses.css";
import {useSelector} from "react-redux";
import InstitutionUserMasterClassDetails from "./InstitutionUserMasterClassDetails";
import SpeakerMasterClassDetails from "./SpeakerMasterClassDetails";

const MasterClassDetails = () => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);

    if (userType === 4) {
        return <SpeakerMasterClassDetails />
    }

    return <InstitutionUserMasterClassDetails />;
};

export default MasterClassDetails;