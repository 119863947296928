import React from "react";
import defaultImage from "../../assets/avatarUser.png";
import "./DiscussionsCommentList.css";
import ChatDot from "../../assets/chat-dot.png";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {CommentsService} from "../../services/commentsService/commentsService";
import {toast} from "react-toastify";
import {FaStar} from "react-icons/fa";
import {Dropdown} from "react-bootstrap";
import {BsThreeDots} from "react-icons/bs";
import {useSelector} from "react-redux";

const Comment = ({
                     comment,
                     level = 0,
                     relatedTitlesToDisplay = [],
                     path,
                     onReplyClick,
                     onEditCommentClick,
                     onDeleteCommentClick,
                     withBottomLine = true,
                     onUpdateComment,
                     highlightingCompareId = null
                 }) => {
    const navigate = useNavigate();
    const userData = useSelector((state) => state?.auth?.userData);

    const replyLevel = level + 1;

    const handleLike = async () => {
        try {
            const updatedComment = await CommentsService.likeComment(path, comment.id);
            onUpdateComment(updatedComment.data);
            toast.success('Done!');
        } catch (err) {
            toast.error('Like comment failed. Please try again.');
        }
    };

    return (
        <>
            <div className={`discussion-comment-wrapper ${withBottomLine ? 'border-bottom border-secondary' : ''}`}>
                <div
                    className="discussion-comment"
                    style={level > 0 ? {
                        paddingLeft: `${level + 1.5}rem`,
                        paddingRight: '1.5rem',
                        paddingBottom: '1.5rem'
                    } : {padding: '1.5rem'}}
                >
                    <div className="comment__title d-flex justify-space-between">
                        <div className="d-flex align-center flex-wrap comment__author">
                            <div className="d-flex align-center flex-wrap cursor-pointer" onClick={() => navigate(`/community/users/${comment?.user.id}`)}>
                                {comment?.user?.avatar ? (
                                    <img crossOrigin="anonymous" className="comment__author__profile-image object-fit-cover"
                                         src={comment?.user?.avatar} alt="avatar"/>
                                ) : (
                                    <img crossOrigin="anonymous" className="comment__author__profile-image object-fit-cover"
                                         src={defaultImage} alt="default avatar"/>
                                )}
                                {comment?.user?.id === highlightingCompareId
                                    ?
                                    <div
                                        className="comment__author__name text-white"
                                        style={{
                                            backgroundColor: "rgb(59, 71, 112)",
                                            padding: "2px 6px",
                                            borderRadius: "20px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        {comment?.user?.name} <FaStar style={{marginLeft: "0.25rem"}} fill="#faaf00" />
                                    </div>
                                    :
                                    <div className="comment__author__name text-white">
                                        {comment?.user?.name}
                                    </div>
                                }
                            </div>
                            <time className="comment__time text-secondary" title={comment?.posted_at}>
                                {comment?.posted_at}
                            </time>
                        </div>
                        {userData?.id === comment?.user?.id &&
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="comment-actions__dropdown-toggle">
                                    <BsThreeDots
                                        height="20" width="20" radius="9" fill="gray"
                                        color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="comment-actions__dropdown">
                                    <Dropdown.Item className="comment-actions__dropdown__item" onClick={() => {
                                        onEditCommentClick(comment);
                                    }}>
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item className="comment-actions__dropdown__item" onClick={() => {
                                        onDeleteCommentClick(comment);
                                    }}>
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>
                    <p className="text-light">{comment?.body}</p>
                    <div className="d-flex align-center justify-space-between">
                        <div
                            className="d-flex align-center cursor-pointer"
                            onClick={() => {
                                onReplyClick(comment);
                            }}
                        >
                            <img className="comment__reply__icon" crossOrigin="anonymous" src={ChatDot}
                                 style={{marginRight: "4px"}} alt="reply"/>
                            <span className="text-light comment__reply__text">Reply</span>
                        </div>
                        <div onClick={handleLike}>
                            {comment?.is_liked ? (
                                <AiFillHeart
                                    fill="red"
                                    fontSize={18}
                                    style={{cursor: "pointer"}}
                                />
                            ) : (
                                <AiOutlineHeart
                                    fontSize={18}
                                    style={{cursor: "pointer"}}
                                />
                            )}
                        </div>
                    </div>
                    {relatedTitlesToDisplay.includes(comment?.related_to?.type) && (
                        <div className="d-flex justify-content-end mt-2">
                        <span className="w-50 comment__related-to">
                            <span className="text-secondary">to</span>
                            &nbsp;
                            <span className="comment__related-to__link">
                                {comment?.related_to?.name}
                            </span>
                        </span>
                        </div>
                    )}
                </div>
                {comment.replies?.map((reply) => (
                    <Comment
                        key={reply.id}
                        comment={reply}
                        level={replyLevel}
                        path={path}
                        onReplyClick={onReplyClick}
                        onEditCommentClick={onEditCommentClick}
                        onDeleteCommentClick={onDeleteCommentClick}
                        withBottomLine={false}
                        onUpdateComment={onUpdateComment}
                        highlightingCompareId={highlightingCompareId}
                    />
                ))}
            </div>
        </>
    );
};

export default Comment;