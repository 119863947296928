import apiClient from "../../utils/apiClient";

const getChats = (cursor) => {
    const url = `chats${cursor ? `?cursor=${cursor}` : ''}`;
    return apiClient.get(url);
};

const getChatById = (chatId) => {
    const url = `chats/${chatId}`;
    return apiClient.get(url);
};

const getChatMessages = (chatId, cursor) => {
    const url = `chats/${chatId}/messages${cursor ? `?cursor=${cursor}` : ''}`;
    return apiClient.get(url);
};

const sendMessage = (data) => {
    const url = `messages`;
    return apiClient.post(url, data);
};

const findChatByUserId = (data) => {
    const url = `chats/find`;
    return apiClient.post(url, data);
};

export const ChatsService = {
    getChats,
    getChatMessages,
    sendMessage,
    findChatByUserId,
    getChatById
};