import apiClient from "../../utils/apiClient";

const GET_LESSON_BY_ID = "lessons";
const UPDATE_PROGRESS = "lessons/update-progress";
const COMPLETE_LESSON = "lessons/complete";

const getSingleLessonData = (id) => {
    const url = `${GET_LESSON_BY_ID}/${id}`;
    return apiClient.get(url);
};

const getSpeakerSingleLessonData = (id) => {
    const url = `speaker/lessons/${id}`;
    return apiClient.get(url);
};

const updatedLessonProgress = (id, duration, currentTime) => {
    const data = {
        lesson_id: id,
        duration: duration,
        current_time: currentTime,
    };
    const url = `${UPDATE_PROGRESS}`;

    return apiClient.post(url, data);
};

const completeLesson = (id) => {
    const data = {
        lesson_id: id,
    };
    const url = `${COMPLETE_LESSON}`;

    return apiClient.post(url, data);
};

const rateLesson = (id, rating) => {
    const data = {
        rating: rating
    };
    const url = `lessons/${id}/rate`;

    return apiClient.post(url, data);
};

export const LessonsService = {
    getSingleLessonData,
    updatedLessonProgress,
    completeLesson,
    rateLesson,
    getSpeakerSingleLessonData
};