import moment from "moment";
import React, { useState, useEffect } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import { PiLockSimpleLight } from "react-icons/pi";
import UpcomingEvent from "../../../assets/dashboard/upcoming.png";
import convertUtcToSelectedTimeZone from "../../../utils/common-functions/ConvertUtcToTimezone";
import imageNotFound from "../../../assets/Logo/catapult_logo.png";
import convertUtcToPst8pdt from "../../../utils/common-functions/ConvertUtcToPst8pdt";
import {MEETING_TYPES, ROUTES} from "../../../constants";
import {useNavigate} from "react-router-dom";

const LockedSpecialEvents = ({ events }) => {
    const navigate = useNavigate();
    const [maxCardHeight, setMaxCardHeight] = useState(0);

    useEffect(() => {
        const cardHeights = Array.from(document.getElementsByClassName("special-event-card")).map((card) => card.clientHeight);
        const maxHeight = Math.max(...cardHeights);
        setMaxCardHeight(maxHeight);
    }, [events]);

    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleEventJoin = (id, alternativeUrl) => {
        if (alternativeUrl) {
            window.open(alternativeUrl, '_blank');
        }
        if (!alternativeUrl) {
            navigate("/live-events/" + id);
            scrollToTop();
        }
    };

    return (
        <>
            {events?.length > 0 && (
                <>
                    <div className="d-flex gap-2 align-items-center">
                        <h5 className="m-0">Upcoming Event</h5>
                        <img src={UpcomingEvent} alt="live event" className="live-image" />
                    </div>

                    <Row className=" mt-3">
                        {events.map((event, index) => {
                            const startDate = moment(convertUtcToPst8pdt(event.start_date));
                            const currentDate = new Date();

                            const pst8pdtCurrentTime = currentDate.toLocaleString("en-US", {
                                timeZone: "America/Los_Angeles",
                                hour12: true,
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            });
                            const isEventActive = startDate.isAfter(new Date(pst8pdtCurrentTime));

                            return (
                                <Col key={index} md={6} lg={6} xxl={4} sm={12} xs={12} className="mb-4" style={{ cursor: "pointer" }} onClick={() => handleEventJoin(event.id, event.alternative_meeting_url)}>
                                    <Card className={`m-2 special-event-card special-event-card-body`} style={{ height: maxCardHeight + "px" }}>
                                        {event?.full_cover_image === null ? (
                                            <Card.Img variant="top" src={imageNotFound} alt={event?.title} className="special-event-image" />
                                        ) : (
                                            <Card.Img variant="top" src={event?.full_cover_image} alt={event?.title} className="special-event-image" />
                                        )}
                                        <Card.Body className="special-event-card-body">
                                            <Card.Title>{truncateText(event?.title, 50)}</Card.Title>

                                            <Button variant="primary" disabled={isEventActive}>
                                                Join Event
                                            </Button>
                                        </Card.Body>
                                        {isEventActive && (
                                            <div className="overlay">
                                                <div className="d-flex gap-2 justify-content-center align-items-center">
                                                    <PiLockSimpleLight className="fs-1" />
                                                    <div>
                                                        <div className="session-lock-subtext">Event will be available:</div>
                                                        <div className="session-lock-date"> {startDate.format("MMMM DD, YYYY")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </>
            )}
        </>
    );
};

export default LockedSpecialEvents;
