import React, {useEffect, useState} from "react";
import Loader from "../../components/common/Loader";
import {useSelector} from "react-redux";
import CommonZoomPlayComponent from "../../components/Zoom/CommonZoomPlayComponent";
import {DashboardService} from "../../services/dashboardService/dashboardService";
import {useParams} from "react-router-dom";
import helpers from "../../utils/common-functions/Helper";

const LiveEvent = () => {

    const authUser = useSelector((state) => state?.auth?.userData);
    const params = useParams();

    const [state, setState] = useState({
        loader: false,
        otherMeetingDetails: null,
    });

    useEffect(() => {
        let requestType = "special_events";
        getOtherMeetingDetails(requestType, params.id);
    }, [params]);

    const updateState = (_state) => {
        setState((prevStates) => {
            return {
                ...prevStates,
                ..._state,
            };
        });
    };

    const getOtherMeetingDetails = (requestType, id) => {
        updateState({ loader: true });
        DashboardService.getOtherMeetingDetails(requestType, id)
            .then((res) => {
                let _updateState = {
                    otherMeetingDetails: "",
                    loader: false,
                };
                if (!helpers.isEmpty(res?.data?.data?.meeting_details) && !helpers.isEmpty(res?.data?.data?.session)) {
                    _updateState = {
                        ..._updateState,
                        otherMeetingDetails: res?.data?.data,
                    };
                }
                updateState(_updateState);
            })
            .catch((e) => {
                updateState({ loader: false });
            });
    };

    return (
        <>
            <div className="container-fluid">
                <h3 className="d-lg-block">{state?.otherMeetingDetails?.meeting_details?.meetingTitle}</h3>
                {state?.loader ? (
                    <Loader />
                ) : (
                    <>
                        {state?.otherMeetingDetails && (
                            <>
                                <div className="card-layout-video mt-4">
                                    <CommonZoomPlayComponent
                                        zoomSession={state.otherMeetingDetails.session}
                                        meetingDetails={{
                                            meetingId: state.otherMeetingDetails.meeting_details?.meetingId,
                                            userName: `${authUser?.first_name} ${authUser?.last_name}`,
                                            userId: `${authUser.id}`,
                                            zoomMeetingPassword: state.otherMeetingDetails.meeting_details?.zoomMeetingPassword,
                                            userEmail: authUser?.email,
                                            zoomAccessToken: state.otherMeetingDetails.meeting_details?.zoomAccessToken,
                                            role: 0 // can be 1 also, need to re-check,
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default LiveEvent;