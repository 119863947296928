import React, {useEffect, useState} from "react";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import DiscussionsList from "./DiscussionsList";
import {Pagination} from "../../../components/Pagination/Pagination";
import useDebounce from "../../../hooks/useDebounce";
import {FiSearch} from "react-icons/fi";

const CommunityQuestions = () => {
    const [communityQuestions, setCommunityQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [requestPage, setRequestPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 750);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getCommunityQuestions();
    }, [requestPage, debouncedSearchTerm]);

    const getCommunityQuestions = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getCommunityQuestions(12, requestPage, searchTerm);
            setCommunityQuestions(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setTotalPages(response.data.meta.last_page);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setRequestPage(page);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <DiscussionsList
                            title="Questions for the Community"
                            discussions={communityQuestions}
                            navigateTo="/community/questions-for-the-community/"
                            sideComponent={
                                <div className="search-container">
                                    <input onChange={handleSearch} value={searchTerm} placeholder="Search"
                                           className="search-input" autoFocus={true}/>
                                    <FiSearch className="search-icon"/>
                                </div>
                            }
                        />
                        <div className="mt-2">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CommunityQuestions;