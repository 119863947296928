import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import React from "react";
import UserDetails from "../../../components/UserDetails";
import {useNavigate} from "react-router-dom";

const DiscussionCard = ({ discussion, navigateTo }) => {
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4" onClick={() => navigate(navigateTo + discussion.id)}>
            <div className="card h-100 text-white m-3 p-4 program-masterclass-card rounded-3 d-flex flex-column justify-content-between">
                <div>
                    <h5 className="card-title mb-3">{discussion?.title}</h5>
                    <div className="discussion-card-description">
                        {discussion?.description &&
                            renderAnswer(discussion?.description)?.map((element, index) => (
                                <React.Fragment
                                    key={index}>{element}</React.Fragment>
                            ))}
                    </div>
                </div>
                {discussion?.user &&
                    <UserDetails
                        user={discussion?.user}
                    />
                }
            </div>
        </div>
    );
};

export default DiscussionCard;