import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {ChatsService} from "../../../services/chatsService/chatsService";
import CommentLoader from "../../../components/common/CommentLoader";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import usePusher from "../../../hooks/usePusher";
import {toast} from "react-toastify";
import avatarUser from "../../../assets/profile-settings/avatarImage.jpg";
import {useNavigate} from "react-router-dom";
import {closeChatDropdown} from "../../../redux/messengerSlice";

const ChatMessages = ({ selectedChat, onChatUpdated }) => {
    const dispatch = useDispatch();
    const [messages, setMessages] = useState([]);
    const [nextCursor, setNextCursor] = useState(null);
    const [messageInput, setMessageInput] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const userData = useSelector((state) => state?.auth?.userData);
    const pusher = usePusher();
    const navigate = useNavigate();

    const fetchMessages = async (cursor = null) => {
        try {
            const response = await ChatsService.getChatMessages(selectedChat?.id, cursor);
            const newMessages = response.data.data.reverse();

            if (newMessages.length === 0 || !response.data.meta.next_cursor) {
                setMessages((prevMessages) => [...newMessages, ...prevMessages]);
                setHasMore(false);
            } else {
                setMessages((prevMessages) => [...newMessages, ...prevMessages]);
                setNextCursor(response.data.meta.next_cursor);
            }
        } catch (error) {
            console.error("Load messages error: ", error);
        }
    };

    useEffect(() => {
        if (selectedChat && selectedChat.id) {
            setMessages([]);
            setHasMore(true);
            fetchMessages();
        } else {
            setMessages([]);
            setHasMore(false);
        }
    }, [selectedChat]);

    // pusher channels
    useEffect(() => {
       if (pusher) {
           if (!selectedChat) return;

           const channel = pusher.subscribe(`chat.${selectedChat?.id}`);

           channel.bind("message.sent", data => {
               if (data.sender_id === userData.id) {
                   return;
               }

               setMessages(prevMessages => [...prevMessages, data.message]);
           });

           return () => pusher.unsubscribe(`chat.${selectedChat?.id}`);
       }
    }, [selectedChat, pusher]);

    const handleSendMessage = async () => {
        if (messageInput.trim()) {
            setMessageInput("");

            const data = {
                recipient_id: selectedChat.user.id,
                text: messageInput,
            };

            try {
                const response = await ChatsService.sendMessage(data);
                const newMessage = response.data;
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                onChatUpdated(newMessage.chat_id);
            } catch (error) {
                toast.error("Failed to send message. Please try again.");
            }
        }
    };

    const handleNavigateToUserProfile = (userId) => {
        navigate(`/community/users/${userId}`);
        dispatch(closeChatDropdown());
    };

    return (
        <>
            <div className="chat-content"
                 style={{width: "55%", display: "flex", flexDirection: "column", padding: "1rem"}}>
                {selectedChat ? (
                    <>
                        <p className="fs-5 lh-base fw-bolder text-white">
                            {selectedChat?.user?.name}
                        </p>
                        <div
                            id="scrollableDiv"
                            style={{
                                height: "500px",
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column-reverse",
                            }}
                        >
                            <InfiniteScroll
                                dataLength={messages.length}
                                next={() => fetchMessages(nextCursor)}
                                hasMore={hasMore}
                                loader={<CommentLoader/>}
                                inverse={true}
                                scrollableTarget="scrollableDiv"
                            >
                                {messages.map((msg) => (
                                    <div
                                        key={msg.id}
                                        className={`mb-2 d-flex ${
                                            msg.user.id === userData?.id
                                                ? "justify-content-end"
                                                : "justify-content-start"
                                        }`}
                                    >
                                        {msg.user.id === userData?.id ?
                                            <>
                                                <span
                                                    style={{
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        background: msg.user.id === userData?.id ? "#0d6efd" : "#e9ecef",
                                                        color: msg.user.id === userData?.id ? "#fff" : "#000",
                                                    }}
                                                >
                                                    {msg.text}
                                                </span>
                                                <div className="chat-recipient-avatar__right cursor-pointer" onClick={() => handleNavigateToUserProfile(msg?.user?.id)}>
                                                    {msg?.user?.avatar ? (
                                                        <img crossOrigin="anonymous" className="chat-recipient-avatar"
                                                             src={msg?.user?.avatar} alt="avatar"/>
                                                    ) : (
                                                        <img crossOrigin="anonymous" className="chat-recipient-avatar"
                                                             src={avatarUser} alt="default avatar"/>
                                                    )}
                                                </div>
                                            </> :
                                            <>
                                                <div className="chat-recipient-avatar__left cursor-pointer" onClick={() => handleNavigateToUserProfile(msg?.user?.id)}>
                                                    {msg?.user?.avatar ? (
                                                        <img crossOrigin="anonymous" className="chat-recipient-avatar"
                                                             src={msg?.user?.avatar} alt="avatar"/>
                                                    ) : (
                                                        <img crossOrigin="anonymous" className="chat-recipient-avatar"
                                                             src={avatarUser} alt="default avatar"/>
                                                    )}
                                                </div>
                                                <span
                                                    style={{
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        background: msg.user.id === userData?.id ? "#0d6efd" : "#e9ecef",
                                                        color: msg.user.id === userData?.id ? "#fff" : "#000",
                                                    }}
                                                >
                                                    {msg.text}
                                                </span>
                                            </>
                                        }
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </div>
                        <Form>
                            <Form.Group className="d-flex">
                                <Form.Control
                                    type="text"
                                    placeholder="Type a message..."
                                    className="mr-5"
                                    value={messageInput}
                                    onChange={(e) => setMessageInput(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleSendMessage();
                                        }
                                    }}
                                />
                                <Button variant="primary" onClick={handleSendMessage}>
                                    Send
                                </Button>
                            </Form.Group>
                        </Form>
                    </>
                ) : (
                    <p className="fs-5 lh-base fw-bolder text-white">
                        Select a chat to view messages
                    </p>
                )}
            </div>
        </>
    );
};

export default ChatMessages;