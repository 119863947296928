import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isChatDropdownOpen: false,
    newChatUserData: null,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        openChatDropdown: (state, action) => {
            state.isChatDropdownOpen = true;
        },
        closeChatDropdown: (state) => {
            state.isChatDropdownOpen = false;
            state.newChatUserData = null;
        },
        setNewChatUserData: (state, action) => {
            const user = action.payload;
            state.newChatUserData = {
                'user': {
                    'name': user.name,
                    'avatar': user.avatar,
                    'id': user.id,
                },
                'last_message': null,
                'has_unread_messages': false
            };
            state.isChatDropdownOpen = true;
        },
    },
});

export const {
    openChatDropdown,
    closeChatDropdown,
    setNewChatUserData
} = chatSlice.actions;
export default chatSlice.reducer;