import {useMemo} from "react";
import {toast} from "react-toastify";

const useDownload = () => {
    const downloadFile = async (fileUrl, filename) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            toast.success(`"${filename}" downloaded successfully`);
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return useMemo(() => downloadFile, []);
};

export default useDownload;