import React from "react";
import {Link} from "react-router-dom";
import {Breadcrumbs, Typography} from "@mui/material";
import { ReactComponent as BreadcrumbsSeparator } from "../../assets/right-icon.svg";
import "./CustomBreadcrumbs.css";

const CustomBreadcrumbs = ({ pages, currentPageTitle }) => {
    return (
        <>
            <Breadcrumbs className="mb-3" separator={<BreadcrumbsSeparator />} aria-label="breadcrumb">
                {pages?.map((page) => {
                    return (
                        <Link className="custom-breadcrumbs__item" to={page.to}>
                            {page.title}
                        </Link>
                    );
                })}
                <Typography className="custom-breadcrumbs__current-page-title">{currentPageTitle}</Typography>
            </Breadcrumbs>
        </>
    );
};

export default CustomBreadcrumbs;