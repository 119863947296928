import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import UserDetails from "../../../components/UserDetails";
import {useSelector} from "react-redux";
import {FaRegEdit} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {PageErrorMessage} from "../../../components/PageErrorMessage";
import EditMemberDiscussionModal from "../modals/EditMemberDiscussionModal";
import TextEditorContent from "../../../components/TextEditorContent";

const MemberDiscussionDetails = () => {
    const [memberDiscussionDetails, setMemberDiscussionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const renderAnswer = useRenderTextEditorContent();
    const userData = useSelector((state) => state?.auth?.userData);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getMemberDiscussionDetails();
    }, []);

    const getMemberDiscussionDetails = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getMemberDiscussionsDetails(id);
            setMemberDiscussionDetails(response.data);
            setIsLoading(false);
        } catch (error) {
            setError({
                statusCode: error.response.status,
                message: error.response.data.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteConfirmation = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this discussion?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete();
            }
        });
    };

    const handleDelete = async () => {
        try {
            await communityService.deleteMemberDiscussion(id);
            navigate('/community/member-discussions');
            toast.success('Discussion deleted successfully!');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to delete comment');
        }
    };

    if (error) {
        return <PageErrorMessage
            error={error}
            entityTitle="Member Discussion"
        />;
    }

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="container m-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <h1>{memberDiscussionDetails?.title}</h1>
                                    {memberDiscussionDetails?.user?.id === userData?.id &&
                                        <div className="d-flex justify-content-end my-2">
                                            <div className="d-flex align-items-center gap-1">
                                                <button className="border-0 p-0"
                                                        style={{background: "unset", color: "#198754"}}
                                                        onClick={() => setShowEditModal(true)}
                                                >
                                                    <FaRegEdit fontSize={20}/>
                                                </button>
                                                <button className="border-0 p-0"
                                                        style={{background: "unset", color: "rgba(139, 0, 50, 1)"}}
                                                        onClick={handleDeleteConfirmation}
                                                >
                                                    <MdDelete fontSize={24}/>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    <TextEditorContent
                                        text={memberDiscussionDetails?.description}
                                    />
                                    <div className="master-class__speaker-info p-3 mt-4"
                                         style={{marginLeft: 0, textAlign: "center"}}>
                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                            <div
                                                onClick={() => navigate(`/community/users/${memberDiscussionDetails?.user?.id}`)}>
                                                <UserDetails
                                                    user={memberDiscussionDetails?.user}
                                                />
                                            </div>
                                            <div className="mt-1">
                                                {
                                                    memberDiscussionDetails?.user?.bio && renderAnswer(memberDiscussionDetails?.user?.bio)?.map((element, index) =>
                                                        <React.Fragment key={index}>{element}</React.Fragment>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                <div className="master-class__comments">
                                        <DiscussionsCommentList
                                            path={`community/member-discussions/${id}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showEditModal &&
                            <EditMemberDiscussionModal
                                onClose={() => setShowEditModal(false)}
                                selectedDiscussionId={id}
                                discussionData={memberDiscussionDetails}
                                onUpdateDiscussion={(discussion) => setMemberDiscussionDetails(discussion)}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default MemberDiscussionDetails;