import React, {useState} from "react";
import { Dropdown } from "react-bootstrap";
import "../chat.css";
import ChatContacts from "./ChatContacts";
import ChatMessages from "./ChatMessages";
import {BsChatSquareTextFill} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {closeChatDropdown, openChatDropdown} from "../../../redux/messengerSlice";

export default function ChatDropdown({ mobileView }) {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.chat.isChatDropdownOpen);
    const userData = useSelector((state) => state?.auth?.userData);
    const [selectedChat, setSelectedChat] = useState(null);
    const [updatedChatId, setUpdatedChatId] = useState(null);

    const handleToggle = (isOpen) => {
        if (isOpen) {
            dispatch(openChatDropdown());
        } else {
            dispatch(closeChatDropdown());
            setSelectedChat(null);
        }
    };

    const handleSelectChat = (chat) => {
        setSelectedChat(chat);
    };

    const handleChatUpdated = (chatId) => {
        setUpdatedChatId(chatId);
    };

    const preventCloseOnClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Dropdown
            align="end"
            className="d-inline-block"
            autoClose="manual"
            show={isOpen}
            onToggle={handleToggle}
        >
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="comment-actions__dropdown-toggle">
                <BsChatSquareTextFill />
                {userData?.unread_messages > 0 &&
                    <span className="position-absolute noti-count top-50 start-100 translate-middle badge rounded-pill bg-danger">
                        {userData?.unread_messages}
                    </span>
                }
            </Dropdown.Toggle>

            <Dropdown.Menu
                onClick={preventCloseOnClick}
                style={{
                    minWidth: mobileView ? "90vw" : "50vw",
                    height: "60vh",
                    padding: "0",
                    // display: "flex",
                    // flexDirection: "column",
                }}
            >
                {isOpen &&
                    <div
                        className="chat-container"
                        style={{
                            display: "flex",
                            height: "100%",
                        }}
                    >
                        <ChatContacts
                            selectedChat={selectedChat}
                            onChatSelect={handleSelectChat}
                            updatedChatId={updatedChatId}
                        />
                        <ChatMessages
                            selectedChat={selectedChat}
                            onChatUpdated={handleChatUpdated}
                        />
                    </div>
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}