import React from "react";
import MasterClassLessonCard from "./MasterClassLessonCard";
import "../masterClasses.css";

const MasterClassLessonsList = ({ lessons, withProgress = true }) => {

    return (
        <>
            <div className="other-lessons p-3">
                <div className="mb-3">
                    <span className="fs-5 lh-base fw-bolder">
                        Lessons
                    </span>
                </div>
                <div className="master-class-lessons__wrapper">
                    {lessons?.map((lesson) => {
                        return (
                            <React.Fragment key={lesson.id}>
                                <MasterClassLessonCard
                                    lesson={lesson}
                                    withProgress={withProgress}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default MasterClassLessonsList;