import React from "react";
import ProgramCard from "./ProgramCard";

const ProgramsList = ({ programs }) => {
    return (
        <>
            {programs?.map((program) => {
                return (
                    <React.Fragment key={program?.id}>
                        <ProgramCard program={program} />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ProgramsList;