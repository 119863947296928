export const searchCategories = {
    'users': {
        title: 'Users',
        type: 'users',
        path: '/community/users/',
        namingField: 'name'
    },
    'speaker-discussions': {
        title: 'Speaker Discussions',
        type: 'speaker-discussions',
        path: '/master-class/discussions/',
        namingField: 'title'
    },
    'community-questions': {
        title: 'Questions for the Community',
        type: 'community-questions',
        path: '/community/questions-for-the-community/',
        namingField: 'title'
    },
    'member-discussions': {
        title: 'Member Discussions',
        type: 'member-discussions',
        path: '/community/member-discussions/',
        namingField: 'title'
    },
};