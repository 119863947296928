import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import React, {useEffect, useState} from 'react';
import { pdfjs } from 'react-pdf';
import './PdfPreview.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfPreview = ({ fileUrl }) => {
    const [file, setFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        const fetchFile = async () => {
            try {
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error(`Failed to fetch file: ${response.statusText}`);
                }
                const blob = await response.blob();
                setFile(blob);
            } catch (error) {
                console.error("Error fetching the file:", error);
            }
        };

        fetchFile();
    }, [fileUrl]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div className="pdf-preview-container">
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <div className="pdf-page-container">
                    <Page
                        pageNumber={1}
                        width={350}
                    />
                </div>
            </Document>
        </div>
    );
};

export default PdfPreview;