import apiClient from "../../utils/apiClient";

const GET_MASTER_CLASS_BY_ID = "master-classes";

const getMasterClasses = () => {
    return apiClient.get('master-classes');
}

const getSpeakerMasterClasses = () => {
    return apiClient.get('speaker/master-classes');
}

const getSpeakerMasterClassData = (id) => {
    return apiClient.get(`speaker/master-classes/${id}`);
}

const getSingleMasterClassData = (id) => {
    const url = `${GET_MASTER_CLASS_BY_ID}/${id}`;
    return apiClient.get(url);
};

const completeMasterClass = (id) => {
    const url = `master-classes/${id}/complete`;
    return apiClient.post(url);
};

const rateMasterClass = (id, rating) => {
    const data = {
        rating: rating
    };
    const url = `master-classes/${id}/rate`;

    return apiClient.post(url, data);
};

export const MasterClassService = {
    getSingleMasterClassData,
    completeMasterClass,
    getMasterClasses,
    rateMasterClass,
    getSpeakerMasterClasses,
    getSpeakerMasterClassData
};