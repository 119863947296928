import apiClient from "../../utils/apiClient";

const GET_FAQ = "/faq";

const getFaqs = (page, limit, category) => {
    const url = `${GET_FAQ}?page=${page}&limit=${limit}&category=${category}`;

    return apiClient.get(url);
};

export const faqServices = {
    getFaqs,
};
