import CommunitySearchResults from "../../../pages/community/components/CommunitySearchResults";
import React, {useEffect, useRef, useState} from "react";
import useDebounce from "../../../hooks/useDebounce";
import {FiSearch} from "react-icons/fi";
import {communityService} from "../../../services/communityService/communityService";
import "../globalSearch.css";

export const GlobalSearch = () => {
    const [searchResult, setSearchResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const searchRef = useRef(null);

    const debouncedSearchTerm = useDebounce(searchTerm, 750);

    useEffect( () => {
        if (searchTerm) {
            getSearchResults();
        }
    }, [debouncedSearchTerm]);

    const getSearchResults = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.communitySearch(searchTerm);
            setSearchResult(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchTerm('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="global-search" ref={searchRef}>
            <div className="community__main-title__search-container">
                <input onChange={handleSearch} placeholder="Search"
                       className="global-search__main-title__search-container__search-input" value={searchTerm}/>
                <FiSearch className="global-search__main-title__search-container__search-icon"/>
            </div>
            <CommunitySearchResults
                searchResult={searchResult}
                isLoading={isLoading}
                searchTerm={searchTerm}
                style={{
                    padding: "0.5rem",
                    top: 50
                }}
                onSelectResultsItem={() => {
                    setSearchTerm('');
                    setSearchResult([]);
                }}
            />
        </div>
    );
}