import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import {useNavigate} from "react-router-dom";

const OtherLessonCard = ({ lesson, selectedLessonId, withProgress = true }) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/lessons/" + lesson.id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="other-lessons__card p-3 rounded-3" onClick={handleNavigate}>
                <img src={lesson?.cover_image}
                     className="card-img-top program-masterclass-card-thumbnail other-lesson-thumbnail img-fluid rounded-3 mb-3" alt={lesson.title}/>
                <div className="mt-2">
                    <span className="fs-6 lh-base fw-bolder">
                        {lesson.title}
                    </span>
                </div>
                {(withProgress && selectedLessonId == lesson.id) &&
                    <div className="mt-2">
                        <ProgressBar completed={lesson.progress} bgColor="#1c64f2" baseBgColor="#374151"
                                     className={`w-100 progressbar-count ${lesson.progress > 5 ? '' : 'progressbar-count__hide-value'}`}/>
                    </div>
                }
            </div>
        </>
    );
};

export default OtherLessonCard;