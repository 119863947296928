import React, { useEffect, useState } from "react";

import "./innerLayout.css";
import LeftSidebar from "./components/leftSidebar";
import Header from "./components/header";
import Calendar from "../../components/Calendar/Calendar";
import TodoList from "../../components/TodoList/TodoList";
import MasterclassProgressBar from "../../components/ProgressBar/ProgressBar";
import HandWave from "../../assets/dashboard/handWave.svg";
import MileStones from "../../components/MileStones/MileStones";
import Resources from "../../components/Resources/Resources";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { setUserData } from "../../redux/authSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import SidebarCounter from "../../components/SidebarCounter/SidebarCounter";

const InnerLayout = ({ children }) => {
    const userData = useSelector((state) => state?.auth?.userData);
    const loginUserData = useSelector((state) => state?.auth?.auth);
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const studentType = useSelector((state) => state?.auth?.userData?.student_type);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        getUserAllData();
    }, []);

    const getUserAllData = async () => {
        try {
            setIsLoading(true);
            const response = await profileSettingService.getUserProfileData(loginUserData?.id);
            const fetchedUserData = response?.data?.data;
            dispatch(setUserData(fetchedUserData));
            setIsLoading(false);
        } catch (error) {
            console.error("API error:", error);
            setIsLoading(false);
        }
    };

    const isProfileSettingRoute = location.pathname === "/profile-settings";

    return (
        <div className="main-wrapper">
            <Header />
            <LeftSidebar isLoading={isLoading} />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="common-layout-section p-3">
                        {isProfileSettingRoute ? null : (
                            <div className="d-flex d-lg-none justify-content-start align-items-center text-nowrap gap-3 p-2">
                                <img crossOrigin="anonymous" src={HandWave} alt="Hello" width={20} />
                                <div>
                                    {t("COMMON.WELCOME.BACK")}, {userData?.first_name}&nbsp;
                                    {userData?.last_name}
                                </div>
                            </div>
                        )}
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InnerLayout;
