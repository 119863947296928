import React, {useEffect, useState} from "react";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import DiscussionsList from "./DiscussionsList";
import {Pagination} from "../../../components/Pagination/Pagination";
import {FiSearch} from "react-icons/fi";
import useDebounce from "../../../hooks/useDebounce";
import CreateMemberDiscussionModal from "../modals/CreateMemberDiscussionModal";

const MemberDiscussions = () => {
    const [memberDiscussions, setMemberDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [requestPage, setRequestPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const [showCreateMemberDiscussionModal, setShowCreateMemberDiscussionModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getMemberDiscussions();
    }, [requestPage, debouncedSearchTerm]);

    useEffect(() => {
        setRequestPage(1);
    }, [debouncedSearchTerm]);

    const getMemberDiscussions = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getMemberDiscussions(12, requestPage, searchTerm);
            setMemberDiscussions(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setTotalPages(response.data.meta.last_page);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setRequestPage(page);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <DiscussionsList
                            title="Member Discussions"
                            discussions={memberDiscussions}
                            navigateTo="/community/member-discussions/"
                            sideComponent={
                                <div className="d-flex flex-column align-items-end">
                                    <button className="btn btn-primary mb-2" style={{width: "max-content"}}
                                            onClick={() => setShowCreateMemberDiscussionModal(true)}>
                                        Add Discussion
                                    </button>
                                    <div className="search-container">
                                        <input onChange={handleSearch} value={searchTerm} placeholder="Search"
                                               className="search-input" autoFocus={true}/>
                                        <FiSearch className="search-icon"/>
                                    </div>
                                </div>
                            }
                        />
                        <div className="mt-2">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        {showCreateMemberDiscussionModal &&
                            <CreateMemberDiscussionModal
                                onClose={() => setShowCreateMemberDiscussionModal(false)}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default MemberDiscussions;