/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TagSelector from "./Components/TagSelector";

const ProfileSettingsForm = ({ getUserData, getUserAllData, setIsLoading }) => {
    // states start
    const loginUserData = useSelector((state) => state?.auth?.userData);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // states end

    const getUserTypeString = (userType) => {
        switch (userType) {
            case 3:
                return "POC";
            case 4:
                return "Speaker";
            case 5:
                return "Educator";
            default:
                return "";
        }
    };

    // Create a function to handle bio changes
    const handleBioChange = (e) => {
        formik.handleChange(e);
        setIsSubmitting(false);
    };

    useEffect(() => {
        setIsSubmitting(true);
        formik.setValues({
            firstName: getUserData?.first_name || "",
            lastName: getUserData?.last_name || "",
            email: getUserData?.email || "",
            city: getUserData?.city || "",
            state: getUserData?.state || "",
            company: getUserData?.company || "",
            title: getUserData?.title || "",
            profession: getUserData?.user_type || "",
            bio: getUserData?.bio || "",
            tags: getUserData?.tags.map((tag) => ({
                value: tag.id,
                label: tag.name,
            })) || [],
        });
        setIsSubmitting(true);
    }, [getUserData]);

    // Define initial form values
    const initialValues = {
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        company: "",
        title: "",
        bio: "",
        tags: [],
    };

    // Define form validation schema using Yup
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(20, "First Name cannot exceed 20 characters")
            .required("First Name is required"),
        lastName: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(20, "Last Name cannot exceed 20 characters")
            .required("Last Name is required"),
        bio: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(500, "Bio cannot exceed 500 characters")
            .required("Bio is required"),
        state: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "State cannot exceed 100 characters"),
        city: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "City cannot exceed 100 characters"),
        company: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "Company cannot exceed 100 characters"),
        title: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .max(100, "Title cannot exceed 100 characters"),
    });

    // Initialize Formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                setIsSubmitting(true);
                const formData = new FormData();

                formData.append("first_name", values.firstName);
                formData.append("last_name", values.lastName);

                values.tags.forEach((tag, index) => {
                    formData.append(`tag_ids[${index}]`, tag.value);
                });

                formData.append("bio", values.bio);
                formData.append("state", values.state);
                formData.append("city", values.city);
                formData.append("company", values.company);
                formData.append("title", values.title);
                formData.append("_method", "PUT");

                await profileSettingService.addUserProfileData(loginUserData?.id, formData);
                toast.success("Your profile has been updated successfully");
                getUserAllData();
                setIsLoading(false);
            } catch (error) {
                toast.error(error.response?.data?.message || 'Failed to update profile');
                console.error("API error:", error);
                setIsLoading(false);
            }
        },
    });

    return (
        <>
            {/*  JSX code for profile settings form start */}
            <form onSubmit={formik.handleSubmit} className="profile-setting-form form p-0 px-lg-3 px-md-3 ">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="firstName" className="form-label mb-2">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                                autoComplete="off"
                                maxLength={20}
                            />
                            {formik.touched.firstName && formik.errors.firstName ?
                                <div className="text-danger">{formik.errors.firstName}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="lastName" className="form-label mb-2">
                                Last Name
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                                autoComplete="off"
                                maxLength={20}
                            />
                            {formik.touched.lastName && formik.errors.lastName ?
                                <div className="text-danger">{formik.errors.lastName}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="email" className="form-label mb-2">
                                Email
                            </label>
                            <input type="email" id="email" name="email" className="form-control" onBlur={formik.handleBlur}
                                   value={formik.values.email} disabled/>
                            {formik.touched.email && formik.errors.email ?
                                <div className="text-danger">{formik.errors.email}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="firstName" className="form-label mb-2">
                                Title
                            </label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.title}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.title && formik.errors.title ?
                                <div className="text-danger">{formik.errors.title}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="lastName" className="form-label mb-2">
                                State
                            </label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.state && formik.errors.state ?
                                <div className="text-danger">{formik.errors.state}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gl">
                            <label htmlFor="lastName" className="form-label mb-2">
                                City
                            </label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.city && formik.errors.city ?
                                <div className="text-danger">{formik.errors.city}</div> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <div className="input-gr">
                            <label htmlFor="lastName" className="form-label mb-2">
                                Company
                            </label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                className="form-control"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.company}
                                autoComplete="off"
                                maxLength={100}
                            />
                            {formik.touched.company && formik.errors.company ?
                                <div className="text-danger">{formik.errors.company}</div> : null}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 mb-4">
                        <label htmlFor="profession" className="form-label mb-2">
                            Profession
                        </label>
                        <input
                            type="text"
                            id="profession"
                            name="profession"
                            className="form-control"
                            onBlur={formik.handleBlur}
                            value={getUserData?.user_type ? getUserTypeString(getUserData.user_type) : ""}
                            disabled
                        />
                        {formik.touched.profession && formik.errors.profession ?
                            <div className="text-danger">{formik.errors.profession}</div> : null}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4">
                        <label htmlFor="bio" className="form-label mb-2">
                            Bio
                        </label>
                        <textarea
                            type="text"
                            id="bio"
                            name="bio"
                            rows={5}
                            cols={40}
                            className="form-control form-textarea"
                            onChange={handleBioChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bio}
                            style={{resize: "none"}}
                            autoComplete="off"
                            maxLength={500}
                        />
                        {formik.touched.bio && formik.errors.bio ?
                            <div className="text-danger">{formik.errors.bio}</div> : null}
                    </div>
                </div>
                <div className="row">
                    <TagSelector
                        tags={formik.values.tags}
                        setTags={(newTags) => {
                            formik.setFieldValue('tags', newTags);
                            setIsSubmitting(false);
                        }}
                    />
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 ">
                        <button type="submit" className="btn btn-primary" disabled={!formik.dirty || isSubmitting}>
                            Save profile
                        </button>
                    </div>
                </div>
            </form>
            {/*  JSX code for profile setting form end */}
        </>
    );
};

export default ProfileSettingsForm;
