import React, {useEffect, useState} from "react";
import {MasterClassService} from "../../../services/masterClassService/masterClassService";
import Loader from "../../../components/common/Loader";
import MasterClassesList from "./MasterClassesList";
import notFound from "../../../assets/not-found/not_found.png";

const SpeakerMasterclasses = () => {
    const [masterClasses, setMasterClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        getAllMasterClasses();
    }, []);

    const getAllMasterClasses = async () => {
        try {
            const response = await MasterClassService.getSpeakerMasterClasses();

            setMasterClasses(response.data);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <h3 className="d-lg-block">Masterclasses</h3>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        {masterClasses.length > 0 && (
                            <>
                                <MasterClassesList masterClasses={masterClasses} />
                            </>
                        )}
                        {masterClasses?.length === 0 && (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h3>No Masterclasses Added</h3>
                                <p className="fs-5">No Masterclasses available at the moment.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default SpeakerMasterclasses;