import React from 'react';
import MasterClassCard from "./MasterClassCard";

const MasterClassesList = ({ masterClasses }) => {

    return (
        <>
            {masterClasses?.map((masterClass) => {
                return (
                    <React.Fragment key={masterClass?.id}>
                        <MasterClassCard masterClass={masterClass} />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default MasterClassesList;