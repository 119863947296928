import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {communityService} from "../../../services/communityService/communityService";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import CatapultLogo from "../../../assets/catapult_mdl.png";
import ModalClose from "../../../assets/modal_close.png";
import ReactQuill from "react-quill";
import {useNavigate} from "react-router-dom";

const CreateMemberDiscussionModal = ({ onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [quillContent, setQuillContent] = useState('');
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            }),
        description: Yup.string(),
    });

    const handleClose = () => {
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            description: quillContent,
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsDisable(false);
            setIsLoading(true);

            const data = {
                title: values.title,
                description: quillContent,
            };

            try {
                const response = await communityService.createMemberDiscussion(data);
                const newDiscussion = response.data;

                toast.success("Discussion added successfully!");

                formik.resetForm();
                handleClose();
                navigate(`/community/member-discussions/${newDiscussion.id}`);
            } catch (error) {
                toast.error(error.response?.data?.message || "Failed to create discussion. Please try again.");
            }
        },
    });

    const handleDescriptionChange = (value) => {
        if (quillContent !== value) {
            setQuillContent(value);
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    };

    useEffect(() => {
        setIsDisable(false);
    }, []);

    return (
        <>
            <Modal show="true" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-90w" className="add-project-popup ">
                <Modal.Header>
                    <img src={CatapultLogo}></img>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose}></img>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row w-100">
                            <div className="box col-sm-12 col-lg-12 col-md-12">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={!isDisable}
                                    className="ml-30"
                                >
                                    Create
                                </Button>
                            </div>
                            <div className="box col-sm-12 col-lg-12 col-md-12 mt-4 pr-15">
                                <div className="w-100">
                                    <div className="box-2">
                                        <div className="question">
                                            Discussion Title
                                        </div>
                                        <div className="mt-4 w-ful mb-2">
                                            <input
                                                type="text"
                                                name="title"
                                                placeholder="Write your discussion title here"
                                                onChange={(event) => {
                                                    formik.handleChange(event);
                                                    setIsDisable(true);
                                                }}
                                                onBlur={(event) => {
                                                    formik.handleBlur(event);
                                                }}
                                                value={formik.values.title}
                                                autoComplete="off"
                                                maxLength={50}
                                            />

                                            {formik.touched.title && formik.errors.title && <div className="error">{formik.errors.title}</div>}
                                        </div>
                                    </div>

                                    <div className="box-2">
                                        <div className="question">
                                            Discussion Description
                                        </div>
                                        <div className="mt-4 w-ful mb-2">
                                            <ReactQuill
                                                theme="snow"
                                                value={quillContent}
                                                onChange={handleDescriptionChange}
                                                onBlur={(event) => {
                                                    setIsDisable(true);
                                                    formik.handleBlur("description", true);
                                                }}
                                            />

                                            {formik.touched.description && formik.errors.description && <div className="error">{formik.errors.description}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateMemberDiscussionModal;