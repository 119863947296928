import {useNavigate} from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";

const MasterClassLessonCard = ({ lesson, withProgress = true }) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/lessons/" + lesson.id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="text-white card px-4 py-3 cursor-pointer master-class-lesson__card rounded-3" onClick={handleNavigate}>
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <img src={lesson?.cover_image} className="card-img master-class-lesson__card-thumbnail img-fluid rounded-3" alt={lesson.title}/>
                    </div>
                    <div className="col-md-8">
                        <div className="master-class-lesson__card__content">
                            <div className="mt-3">
                            <span className="fs-6 lh-base fw-bolder">
                                {lesson.title}
                            </span>
                            </div>
                            {withProgress &&
                                <div className="mt-3">
                                    <ProgressBar
                                        completed={lesson.progress}
                                        bgColor="#1c64f2"
                                        baseBgColor="#374151"
                                        className="w-100 progressbar-count"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MasterClassLessonCard;