import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    autoplay: false,
};

const lessonsAutoplaySlice = createSlice({
    name: 'lessonsAutoplay',
    initialState,
    reducers: {
        setAutoplay(state, action) {
            state.autoplay = action.payload;
        },
        resetAutoplay(state) {
            state.autoplay = false;
        },
    },
});

export const { setAutoplay, resetAutoplay } = lessonsAutoplaySlice.actions;
export default lessonsAutoplaySlice.reducer;