/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import CatapultLogo from "../../../assets/catapult_mdl.png";
import ModalClose from "../../../assets/modal_close.png";
import "./add-post.css";
import { communityService } from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
const AddPost = ({ onClose, questionId, parentId, isReply = false, isComment = false, isEdit = false, discussionId, getGeneralDiscussion }) => {
    const handleClose = (data) => {
        onClose(data);
    };
    const [getGeneralData, setGetGeneralData] = useState(null);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (isEdit) {
            getGereralDiscussionsById();
        }
    }, [isEdit]);

    useEffect(() => {
        if (isEdit && getGeneralData) {
            setTitle(getGeneralData.title);
            setDescription(getGeneralData.answer);
        }
    }, [isEdit, getGeneralData]);

    const getGereralDiscussionsById = () => {
        communityService.getGeneralDiscussionById(discussionId !== undefined ? discussionId?.id : parentId).then((discussions) => {
            setGetGeneralData(discussions?.data?.data);
        });
    };

    const addPost = () => {
        if (isReply) {
            if (description == "") {
                setIsError(true);
                return false;
            } else {
                setIsError(false);
            }

            setLoading(true);
            communityService
                .addGeneralPost({
                    general_community_question_id: questionId,
                    parent_id: parentId,
                    answer: description,
                })
                .then((res) => {
                    setLoading(false);
                    handleClose(res);
                    getGeneralDiscussion();
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            if (title == "" || description == "") {
                setIsError(true);
                return false;
            } else {
                setIsError(false);
            }
            setLoading(true);
            if (isComment) {
                communityService
                    .addGeneralPost({
                        general_community_question_id: questionId,
                        parent_id: parentId,
                        title: title,
                        answer: description,
                    })
                    .then((res) => {
                        setLoading(false);
                        handleClose(res);
                        toast.success("Your post has been added successfully");
                        getGeneralDiscussion();
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
            } else {
                communityService
                    .addDiscussion({
                        general_community_question_id: questionId,
                        parent_id: parentId,
                        question: title,
                        description: description,
                    })
                    .then((res) => {
                        setLoading(false);
                        handleClose(res);
                    })
                    .catch((err) => {
                        setLoading(false);
                    });
            }
        }
    };

    const editPost = () => {
        if (isEdit) {
            if (title == "" || description == "") {
                setIsError(true);
                return false;
            } else {
                setIsError(false);
            }
            communityService
                .editgeneralDiscussion({
                    general_community_discussion_id: discussionId !== undefined ? discussionId?.id : parentId,
                    title: title,
                    answer: description,
                })
                .then((res) => {
                    setLoading(false);
                    handleClose(res);
                    toast.success("Post updated successfully.");
                    getGeneralDiscussion();
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };

    const handleAddTitle = (e) => {
        setTitle(e.target.value);
    };

    const handleAddDescription = (e) => {
        setDescription(e.target.value);
    };

    const handlePostNow = () => {
        const trimmedTitle = title?.trim();
        const trimmedDescription = description.trim();

        if (isEdit && (!isReply ? trimmedTitle === "" || trimmedDescription === "" : trimmedDescription === "")) {
            setIsError(true);
            return false;
        } else if (!isEdit && (!isReply ? trimmedTitle === "" || trimmedDescription === "" : trimmedDescription === "")) {
            setIsError(true);
            return false;
        } else {
            setIsError(false);
        }

        if (isEdit) {
            editPost();
        } else {
            addPost();
        }
    };

    return (
        <>
            <Modal onHide={handleClose} show="true" size="lg" aria-labelledby="contained-modal-title-vcenter" className={loading ? "add-modal transparent" : "add-modal"} centered>
                {loading ? (
                    <>
                        <Loader></Loader>
                    </>
                ) : (
                    <>
                        <Modal.Header>
                            <img src={CatapultLogo}></img>
                            <div className="modal-close" onClick={() => handleClose()}>
                                <img src={ModalClose}></img>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                            <div className="row w-ful">
                                <div className="box col-sm-12 col-lg-12 col-md-12">
                                    <Button
                                        variant="primary"
                                        className="ml-30"
                                        onClick={handlePostNow}
                                        disabled={!isReply ? title?.trim() == "" || description.trim() == "" : description.trim() == ""}
                                    >
                                        {isEdit ? "Update Post" : "Post Now"}
                                    </Button>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-12 mt-4">
                                    <div>
                                        {!isReply && (
                                            <div className="box-2">
                                                <div className="question">
                                                    Title <span className="question-asterisk">*</span>
                                                </div>
                                                <div className="mt-4 w-ful mb-2">
                                                    <input
                                                        className="form-control-input"
                                                        placeholder="Write your post title here"
                                                        value={title}
                                                        onChange={(e) => {
                                                            handleAddTitle(e);
                                                        }}
                                                    />
                                                </div>
                                                {isError && title == "" && <div className="error">Title is required</div>}
                                            </div>
                                        )}

                                        <div className="box-2 mt-4">
                                            <div className="question">
                                                Description <span className="question-asterisk">*</span>
                                            </div>
                                            <div className="mt-4 w-100">
                                                <textarea
                                                    className="form-control-textarea"
                                                    rows={3}
                                                    cols={50}
                                                    value={description}
                                                    style={{ resize: "none" }}
                                                    onChange={(e) => {
                                                        handleAddDescription(e);
                                                    }}
                                                ></textarea>
                                            </div>
                                            {isError && description == "" && <div className="error">Description is required</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </>
    );
};

export default AddPost;
