import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import TextEditorContent from "../../../components/TextEditorContent";

const MasterClassDiscussionDetails = () => {
    const [masterClassDiscussionDetails, setMasterClassDiscussionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const renderAnswer = useRenderTextEditorContent();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getMasterClassDiscussionDetails();
    }, []);

    const getMasterClassDiscussionDetails = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getMasterClassDiscussionDetails(id);
            setMasterClassDiscussionDetails(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="container m-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <h1>{masterClassDiscussionDetails?.title}</h1>
                                    <TextEditorContent
                                        text={masterClassDiscussionDetails?.description}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="master-class__comments">
                                        <DiscussionsCommentList
                                            path={`master-classes/${id}`}
                                            relatedTitlesToDisplay={['lessons']}
                                            highlightingCompareId={masterClassDiscussionDetails?.user.id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MasterClassDiscussionDetails;