import React from "react";
import notFound from "../../../assets/not-found/not_found.png";
import DiscussionCard from "./DiscussionCard";
import {useNavigate} from "react-router-dom";

const DiscussionsList = ({ discussions, title, navigateTo, sideComponent }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-4">
                <h3 className="d-lg-block">{title}</h3>
                {sideComponent &&
                    sideComponent
                }
            </div>
            {discussions.length > 0 && (
                <div className="row">
                {discussions?.map((discussion) => {
                        return (
                            <React.Fragment key={discussion.id}>
                                <DiscussionCard
                                    discussion={discussion}
                                    navigateTo={navigateTo}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
            {discussions?.length === 0 && (
                <div
                    className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                    style={{height: "300px"}}>
                    <img crossOrigin="anonymous" src={notFound} alt="data not found" width={175}/>
                    <h4>No Discussions</h4>
                    <p className="fs-6">You don't have any {title} at
                        the moment.</p>
                </div>
            )}
        </>
    );
};

export default DiscussionsList;