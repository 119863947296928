import React, {useEffect, useRef, useState} from "react";
import {ListGroup} from "react-bootstrap";
import {ChatsService} from "../../../services/chatsService/chatsService";
import CommentLoader from "../../../components/common/CommentLoader";
import usePusher from "../../../hooks/usePusher";
import {useSelector} from "react-redux";
import avatarUser from "../../../assets/profile-settings/avatarImage.jpg";

const ChatContacts = ({ selectedChat, onChatSelect, updatedChatId }) => {

    const userData = useSelector((state) => state?.auth?.userData);
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState([]);
    const newChatUserData = useSelector((state) => state.chat.newChatUserData);
    const [nextCursor, setNextCursor] = useState(null);
    const chatsRef = useRef(null);
    const pusher = usePusher();

    const handleSelectChat = (chat) => {
        onChatSelect(chat);
    };

    useEffect(() => {
        if (updatedChatId) {
            const existedChat = chats.find(chat => chat.id == updatedChatId);

            if (existedChat) {
                const updatedChats = chats.filter(chat => chat.id !== updatedChatId);
                setChats([existedChat, ...updatedChats]);
                onChatSelect(existedChat);
            } else {
                const fetchChat = async () => {
                    try {
                        const response = await ChatsService.getChatById(updatedChatId);
                        const chat = response.data;
                        setChats(prevChats => [chat, ...prevChats]);
                        onChatSelect(chat);
                    } catch (error) {
                        console.error('Error fetching chat:', error);
                    }
                };

                fetchChat();
            }
        }
    }, [updatedChatId]);

    useEffect(() => {
        const checkOrCreateChat = async () => {
            if (newChatUserData) {
                try {
                    setIsLoading(true);
                    const response = await ChatsService.findChatByUserId({
                        userId: newChatUserData.user.id
                    });

                    if (response?.data?.chat) {
                        onChatSelect(response.data.chat);
                    } else {
                        onChatSelect(newChatUserData);
                    }
                } catch (error) {
                    console.error("Error when chats check:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        checkOrCreateChat();
    }, [newChatUserData]);

    const chatsData = async () => {
        setIsLoading(true);
        setNextCursor(null);
        try {
            const response = await ChatsService.getChats(nextCursor);

            if (nextCursor) {
                setChats((prevComments) => [...prevComments, ...response.data.data]);
            }
            if (!nextCursor) {
                setChats(response.data.data);
            }

            setNextCursor(response.data.meta.next_cursor);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        chatsData();
    }, []);

    useEffect(() => {
        if (!pusher || !userData) return;

        const userChannel = pusher.subscribe(`user.${userData.id}.chats`);

        userChannel.bind("chat.created", data => {
            if (data.sender_id === userData.id) {
                return;
            }

            setChats(prevChats => {
                const newChats = prevChats.filter(chat => chat.id !== data.chat.id);
                return [data.chat, ...newChats];
            });
        });

        const chatChannels = {};

        chats.forEach(chat => {
            if (!chatChannels[chat.id]) {
                chatChannels[chat.id] = pusher.subscribe(`chat_updates.${chat.id}`);

                chatChannels[chat.id].bind("chat.updated", data => {
                    if (data.chat.sender_id === userData.id) {
                        return;
                    }

                    setChats(prevChats => {
                        const updatedChatIndex = prevChats.findIndex(chat => chat.id === data.chat.id);

                        if (updatedChatIndex !== -1) {
                            const updatedChat = {
                                ...prevChats[updatedChatIndex],
                                ...data.chat,
                            };

                            const updatedChatsState = [...prevChats];
                            updatedChatsState.splice(updatedChatIndex, 1);

                            return [updatedChat, ...updatedChatsState];
                        }

                        return prevChats;
                    });
                });
            }
        });

        return () => {
            userChannel.unsubscribe();
            Object.values(chatChannels).forEach(channel => channel.unsubscribe());
        };

    }, [userData, pusher, chats]);

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = chatsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 20) {
            if (nextCursor) {
                chatsData(nextCursor);
            }
        }
    };

    useEffect(() => {
        const chatsElement = chatsRef.current;
        if (chatsElement) {
            chatsElement.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (chatsElement) {
                chatsElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [nextCursor]);

    return (
        <div
            className="chat-list"
            style={{
                width: "45%",
                borderRight: "1px solid rgb(59, 71, 112)",
                overflowY: "auto",
            }}
        >
            <p
                className="fs-5 lh-base fw-bolder text-white"
                style={{
                    padding: "1rem",
                    margin: "0",
                    backgroundColor: "rgba(28, 34, 55, 1)"
                }}
            >
                Contacts
            </p>
            <ListGroup ref={chatsRef}>
                {chats?.map((chat) => {

                    const chatBackground = chat.has_unread_messages ? "#3d4b7a" : "rgba(37, 45, 71, 1)";

                    return (
                        <ListGroup.Item
                            key={chat.id}
                            action
                            onClick={() => handleSelectChat(chat)}
                            style={{
                                position: "relative",
                                borderRadius: "0",
                                backgroundColor: selectedChat?.id === chat.id ? "rgb(55, 65, 81)" : chatBackground,
                                borderTop: "none",
                                borderRight: "none",
                                borderLeft: "none",
                                borderBottom: "1px solid rgb(59, 71, 112)"
                            }}
                        >
                            <div className="d-flex">
                                <div className="mr-5">
                                    {chat?.user?.avatar ? (
                                        <img crossOrigin="anonymous" className="chat-recipient-avatar"
                                             src={chat?.user?.avatar} alt="avatar"/>
                                    ) : (
                                        <img crossOrigin="anonymous" className="chat-recipient-avatar"
                                             src={avatarUser} alt="default avatar"/>
                                    )}
                                </div>
                                <div>
                                    <p className="fs-6 fw-semibold text-white" style={{margin: "0"}}>
                                        {chat.user.name}
                                    </p>
                                    <p className="fs-6 fw-normal text-secondary contacts-list__item__last-message" style={{margin: "0"}}>
                                        {chat?.last_message?.text}
                                    </p>
                                </div>
                            </div>
                            {chat.has_unread_messages && (
                                <span
                                    style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: "rgba(139, 0, 50, 1)",
                                        borderRadius: "50%",
                                        zIndex: 10,
                                    }}
                                ></span>
                            )}
                        </ListGroup.Item>
                    )
                })}
                {isLoading &&
                    <CommentLoader/>
                }
            </ListGroup>
        </div>
    );
};

export default ChatContacts;