import React from "react";
import avatarUser from "../../assets/avatarUser.png";
import "./UserDetails.css";

const UserDetails = ({ user }) => {

    return (
        <div className="d-flex align-items-center cursor-pointer">
            <img
                crossOrigin="anonymous"
                src={user?.avatar || avatarUser}
                alt="Speaker"
                className="user-details__profile-image"
            />
            <span className="fs-6 fw-bold">{user?.name}</span>
        </div>
    );
};

export default UserDetails;