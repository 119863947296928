import {useEffect, useState} from "react";
import Pusher from "pusher-js";

const appKey = process.env.REACT_APP_PUSHER_APP_KEY;
const cluster = process.env.REACT_APP_PUSHER_APP_CLUSTER;
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const usePusher = () => {
    const [pusher, setPusher] = useState(null);

    useEffect(() => {
        const pusherInstance = new Pusher(appKey, {
            cluster: cluster,
            authEndpoint: `${apiUrl}/pusher/auth`,
            auth: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                credentials: true
            },
        });
        setPusher(pusherInstance);

        return () => {
            pusherInstance.disconnect();
        };
    }, []);

    return pusher;
};

export default usePusher;