const getRequestParams = (params) => {
    const requestParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            requestParams.append(key, value);
        }
    });

    return requestParams.toString();
};

export default { getRequestParams };