import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import {LessonsService} from "../../../services/lessonsService/lessonsService";
import Loader from "../../../components/common/Loader";
import YoutubeSession from "../../dashboard/components/YoutubeSession";
import RecordedVideoSession from "../../dashboard/components/RecordedVideoSession";
import defaultImage from "../../../assets/avatarUser.png";
import OtherLessonsList from "./OtherLessonsList";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import {PageErrorMessage} from "../../../components/PageErrorMessage";
import TextEditorContent from "../../../components/TextEditorContent";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";

const SpeakerLessonDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [lessonDetails, setLessonDetails] = useState(null);
    const [otherLessons, setOtherLessons] = useState([]);
    const [showNextLesson, setShowNextLesson] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const [isNextLessonCanceled, setIsNextLessonCanceled] = useState(false);

    const [autoplay, setAutoplay] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();
    const timerRef = useRef(null);
    const intervalRef = useRef(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedAutoplay = localStorage.getItem('autoplay');
        if (storedAutoplay !== null) {
            setAutoplay(storedAutoplay === 'true');
        }
    }, []);

    const handleAutoplayToggle = () => {
        const newAutoplay = !autoplay;
        setAutoplay(newAutoplay);
        localStorage.setItem('autoplay', newAutoplay.toString());
    };

    const startCountdown = () => {
        setCountdown(10);

        intervalRef.current = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        timerRef.current = setTimeout(() => {
            clearInterval(intervalRef.current);
            setCountdown(10);
            setShowNextLesson(false);
            if (lessonDetails.next_lesson) {
                navigate(`/lessons/${lessonDetails.next_lesson.id}`);
            }
        }, 10000);
    };

    const cancelNextLesson = () => {
        setIsNextLessonCanceled(true);
        clearTimeout(timerRef.current);
        clearInterval(intervalRef.current);
        setShowNextLesson(false);
        setCountdown(10);
    };

    const confirmNextLesson = () => {
        clearTimeout(timerRef.current);
        clearInterval(intervalRef.current);
        setCountdown(10);
        setShowNextLesson(false);
        if (lessonDetails.next_lesson) {
            navigate(`/lessons/${lessonDetails.next_lesson.id}`);
        }
    };

    const lessonDetailsData = async () => {
        setIsLoading(true);
        try {
            await LessonsService.getSpeakerSingleLessonData(id).then((response) => {
                setLessonDetails(response?.data);
                setOtherLessons(response?.data.other_lessons);
            });
            setIsLoading(false);
        } catch (error) {
            setError({
                statusCode: error.response.status,
                message: error.response.data.message,
            });
            setIsLoading(false);
        }
    };

    // useEffect of call all api function
    useEffect(() => {
        lessonDetailsData();

        return () => {
            clearTimeout(timerRef.current);
            clearInterval(intervalRef.current);
        };
    }, [id]);

    const handleLessonEnded = async () => {
        if (autoplay && lessonDetails.next_lesson) {
            setShowNextLesson(true);
            startCountdown();
        }
    };

    if (error) {
        return <PageErrorMessage
            error={error}
            entityTitle="Lesson"
        />;
    }

    return (
        <>
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <CustomBreadcrumbs
                        currentPageTitle={lessonDetails?.title}
                        pages={[
                            {
                                title: lessonDetails?.master_class?.name,
                                to: `/master-classes/${lessonDetails?.master_class?.id}`
                            },
                        ]}
                    />
                    <div className="single-lesson-grid">
                        <div className="single-lesson-content p-4">
                            {lessonDetails?.video_url && !showNextLesson ? (
                                <YoutubeSession video={lessonDetails?.video_url} id={id}
                                                onEnded={handleLessonEnded} autoplay={isNextLessonCanceled ? false : autoplay}/>
                            ) : lessonDetails?.video_link && !showNextLesson ? (
                                <RecordedVideoSession video={lessonDetails?.video_link} id={id}
                                                      onEnded={handleLessonEnded} autoplay={isNextLessonCanceled ? false : autoplay}/>
                            ) : null}

                            {showNextLesson &&
                                <div className="next-lesson-overlay">
                                    <div
                                        className="other-lessons__card p-3 rounded-3 d-flex flex-column align-center w-50">
                                        <div className="mb-2">
                                            <span
                                                className="text-secondary">Next video in</span><span> {countdown}</span>
                                        </div>
                                        <img crossOrigin="anonymous" src={lessonDetails?.next_lesson.cover_image}
                                             className="card-img-top program-masterclass-card-thumbnail other-lesson-thumbnail img-fluid rounded-3"
                                             alt={lessonDetails?.next_lesson.title}/>
                                        <div className="mt-2">
                                            <span className="fs-6 lh-base fw-bolder">
                                                {lessonDetails?.next_lesson.title}
                                            </span>
                                        </div>
                                        <div className="d-flex gap-3 mt-2">
                                            <button id="cancelButton" className="btn btn-secondary" onClick={cancelNextLesson}>
                                                Cancel
                                            </button>
                                            <button id="confirmButton" className="btn btn-primary" onClick={confirmNextLesson}>
                                                Play
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="d-flex justify-space-between align-center">
                                 <span className="fs-4 lh-lg fw-bolder">
                                     {lessonDetails?.title}
                                 </span>
                                <div>
                                    <div className="d-flex justify-end mb-2">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                   id="flexSwitchCheckDefault" checked={autoplay}
                                                   onChange={handleAutoplayToggle}/>
                                            <label className="form-check-label"
                                                   htmlFor="flexSwitchCheckDefault">Autoplay</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-0 gap-2 px-lg-3 px-md-3 w-50 align-items-center mb-2">
                                <div className="col-1">
                                    {lessonDetails?.speaker?.avatar !== null ? (
                                        <img crossOrigin="anonymous" className="user-profile-image"
                                             src={lessonDetails?.speaker?.avatar}></img>
                                    ) : (
                                        <img crossOrigin="anonymous" className="user-profile-image"
                                             src={defaultImage}></img>
                                    )}
                                </div>
                                <div className="col-7 mt-1 col-lg-4 col-md-4">
                                    <div className="project-author-name">
                                        {lessonDetails?.speaker?.name}
                                    </div>
                                </div>
                            </div>
                            <TextEditorContent
                                text={lessonDetails?.description}
                            />
                        </div>
                        <OtherLessonsList
                            programTitle={lessonDetails?.program_title}
                            lessons={otherLessons}
                            selectedLessonId={id}
                            withProgress={false}
                        />
                        <DiscussionsCommentList
                            path={`lessons/${lessonDetails?.id}`}
                            highlightingCompareId={lessonDetails?.speaker.id}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default SpeakerLessonDetails;