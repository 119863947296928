import {useNavigate} from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";

const ProgramMasterclassCard = ({ program }) => {
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();

    const handleNavigate = () => {
        navigate("/programs/" + program.id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <div className="card text-white m-3 p-4 program-masterclass-card rounded-3" onClick={handleNavigate}>
                    <img src={program?.cover_image}
                         className="card-img-top program-masterclass-card-thumbnail img-fluid rounded-3 mb-3"
                         alt={program?.name}/>
                    <h5 className="card-title mb-2">{program?.name}</h5>
                    <div>
                                <span className="fs-6 lh-base fw-bolder">
                                    Credit Hours: {program?.credit_hours}
                                </span>
                    </div>
                    <ProgressBar completed={program.progress} bgColor="#1c64f2" baseBgColor="#374151"
                                 className={`w-100 progressbar-count mt-2 ${program.progress > 5 ? '' : 'progressbar-count__hide-value'}`} />
                </div>
            </div>
        </>
    );
};

export default ProgramMasterclassCard;