import notFound from "../../assets/not-found/not_found.png";
import React from "react";

export const PageErrorMessage = ({ error, entityTitle }) => {

    if (error?.statusCode === 403) {
        return <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{height: "400px"}}>
            <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
            <h4>{entityTitle} is not available</h4>
            <p className="fs-6">{error?.message}</p>
        </div>
    }

    if (error?.statusCode === 404) {
        return <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{height: "400px"}}>
            <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
            <h4>{entityTitle} not found</h4>
        </div>
    }

    return (
        <div
            className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
            style={{height: "400px"}}
        >
            <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
            <h4>Something went wrong. Please try again.</h4>
        </div>
    );
};