/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useRef, useState} from "react";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { toast } from "react-toastify";
import avatarImage from "../../assets/profile-settings/avatarImage.jpg";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ImageUploadedLoader from "../../components/common/ImageUploadedLoader";
import AvatarEditor from "react-avatar-editor";

const ProfileImageUpdated = ({ getUserData, getUserAllData }) => {
    const loginUserData = useSelector((state) => state?.auth?.userData?.full_profile_image);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(loginUserData === null);
    const [isEditing, setIsEditing] = useState(false);
    const [scale, setScale] = useState(1);
    const editorRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const maxSizeInBytes = 10 * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                toast.error("File size exceeds the maximum limit of 10MB. Please select a smaller file.");
                return;
            }

            if (!isValidFileFormat(file)) {
                toast.error("Invalid file format. Please select a .png, .jpg, or .jpeg file.");
                return;
            }

            setSelectedFile(file);
            setIsEditing(true); // Переход к этапу редактирования
        }
    };

    const isValidFileFormat = (file) => {
        const validExtensions = [".png", ".jpg", ".jpeg"];
        const fileName = file.name.toLowerCase();
        return validExtensions.some((extension) => fileName.endsWith(extension));
    };

    const handleDeletePicture = () => {
        Swal.fire({
            title: "Confirm to delete",
            text: "Are you sure you want to delete your profile picture?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                setSelectedFile(null);
                uploadProfilePicture(null);
            }
        });
    };

    const uploadProfilePicture = async (croppedImage) => {
        setIsDeleteButtonDisabled(true);
        setIsLoader(true);
        try {
            const formData = new FormData();
            if (croppedImage) {
                formData.append("profile_image", croppedImage);
            } else {
                formData.append("profile_image", "");
            }
            formData.append("_method", "PUT");

            await profileSettingService.updatedProfileImage(formData);

            getUserAllData();
            setIsLoader(false);
            setIsDeleteButtonDisabled(false);
            toast.success("Your profile has been updated successfully");
        } catch (error) {
            console.error("API error:", error);
        }
    };

    const handleSave = async () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            canvas.toBlob(async (blob) => {
                await uploadProfilePicture(blob);
                setIsEditing(false);
                setSelectedFile(null);
            });
        }
    };

    return (
        <div className="profile-setting-form">
            <div className="profile-image-container">
                {!isEditing ? (
                    <div className="profile-image mb-3">
                        <figure style={{ position: "relative", overflow: "hidden" }}>
                            {isLoader && (
                                <div className="profile-image-loader">
                                    <ImageUploadedLoader />
                                </div>
                            )}
                            <img
                                crossOrigin="anonymous"
                                className="img-fluid object-fit-cover"
                                src={selectedFile ? URL.createObjectURL(selectedFile) : getUserData?.full_profile_image || avatarImage}
                                alt="Profile Image"
                            />
                        </figure>
                        <input type="file" id="profilePictureInput" accept=".png, .jpg, .jpeg" style={{ display: "none" }} onChange={handleFileChange} />
                        <div className="profile-img-btn mb-3">
                            <label htmlFor="profilePictureInput" className="btn change-btn">
                                Change picture
                            </label>
                            <button className={`btn delete-btn ${isDeleteButtonDisabled ? "disabled" : ""}`} onClick={handleDeletePicture}>
                                Delete picture
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="editor-container">
                        <AvatarEditor
                            ref={editorRef}
                            image={selectedFile}
                            width={250}
                            height={250}
                            border={50}
                            scale={scale}
                            borderRadius={125}
                        />
                        <div className="editor-controls d-flex align-items-center">
                            <div className="d-flex flex-column mr-5">
                                Zoom:
                                <label>
                                    <input
                                        type="range"
                                        min="1"
                                        max="3"
                                        step="0.1"
                                        value={scale}
                                        onChange={(e) => setScale(parseFloat(e.target.value))}
                                    />
                                </label>
                            </div>
                            <button
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileImageUpdated;
