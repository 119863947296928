import React from "react";
import "../lessons.css";
import {useSelector} from "react-redux";
import InstitutionUserLessonDetails from "./InstitutionUserLessonDetails";
import SpeakerLessonDetails from "./SpeakerLessonDetails";

const MasterClassDetails = () => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);

    if (userType === 4) {
        return <SpeakerLessonDetails />
    }

    return <InstitutionUserLessonDetails />;
};

export default MasterClassDetails;