import React, {useState} from "react";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import CatapultLogo from "../../assets/catapult_mdl.png";
import ModalClose from "../../assets/modal_close.png";
import {CommentsService} from "../../services/commentsService/commentsService";

const CommentReplyModal = ({ show, commentId, handleClosePopup, path, onAddReply }) => {
    const [comment, setComment] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleClose = () => {
        setComment("");
        handleClosePopup();
    };

    const handleCommentChange = (e) => {
        setIsButtonDisabled(false);
        setComment(e.target.value);
    };
    const addCommentData = async () => {
        setIsButtonDisabled(true);

        const data = {
            parent_id: commentId,
            body: comment,
        };

        try {
            const response = await CommentsService.addComment(path, data);
            const newReply = response.data;

            toast.success("Your reply has been added successfully");

            if (onAddReply) {
                onAddReply(newReply);
            }

            handleClose();
        } catch (error) {
            toast.error("Failed to add reply. Please try again.");
        }
    };

    if (!show) {
        return <></>;
    }

    return (
        <>
            <Modal show={true} onHide={handleClose} className="comment-reply-popup modal-lg">
                <Modal.Header>
                    <img src={CatapultLogo}></img>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose}></img>
                    </div>
                </Modal.Header>
                <Modal.Footer className="my-3 mx-3">
                    <Button variant="primary" onClick={addCommentData} disabled={isButtonDisabled}>
                        Save Reply
                    </Button>
                </Modal.Footer>

                <Modal.Body className="mx-3 my-3">
                    <div className="comment__message-form-reply">
                            <textarea
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder="Write a comment..."
                                className="w-ful py-2"
                                rows={4}>
                            </textarea>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CommentReplyModal;