import React, {useEffect, useState} from "react";
import {communityService} from "../../../services/communityService/communityService";
import Loader from "../../../components/common/Loader";
import {useParams} from "react-router-dom";
import useRenderTextEditorContent from "../../../hooks/useTextEditorContent";
import DiscussionsCommentList from "../../../components/DiscussionsCommentList";
import TextEditorContent from "../../../components/TextEditorContent";

const CommunityQuestionDetails = () => {
    const [communityQuestionDetails, setCommunityQuestionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const renderAnswer = useRenderTextEditorContent();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getCommunityQuestionDetails();
    }, []);

    const getCommunityQuestionDetails = async () => {
        setIsLoading(true);
        try {
            const response = await communityService.getCommunityQuestionDetails(id);
            setCommunityQuestionDetails(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="container m-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <h1>{communityQuestionDetails?.title}</h1>
                                    <TextEditorContent
                                        text={communityQuestionDetails?.description}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="master-class__comments">
                                        <DiscussionsCommentList
                                            path={`community/community-questions/${id}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CommunityQuestionDetails;