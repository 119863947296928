import React from "react";
import DashboardProgramCard from "./DashboardProgramCard";

const DashboardProgramsList = ({ programs }) => {

    return (
        <>
            <h3>Programs</h3>
            <div className="row">
                {programs?.map((program) => {
                    return (
                        <React.Fragment key={program.id}>
                            <DashboardProgramCard
                                program={program}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default DashboardProgramsList;