import React, { useEffect, useState } from "react";
import "./profileSettings.css";
import ProfileSettingsForm from "./ProfileSettingsForm";
import ProfileImageUpdated from "./ProfileImageUpdated";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/authSlice";
import Loader from "../../components/common/Loader";
import AchievementsListCard from "../../components/Achievements/AchievementsListCard";
import useRenderTextEditorContent from "../../hooks/useTextEditorContent";
import {useNavigate} from "react-router-dom";

const ProfileSettings = () => {
    // states start
    const loginUserData = useSelector((state) => state?.auth?.auth);
    const [getUserData, setGetUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const renderAnswer = useRenderTextEditorContent();
    const navigate = useNavigate();
    const userData = useSelector((state) => state?.auth?.userData);

    const dispatch = useDispatch();
    // states end

    // useEffect for getting all user data
    useEffect(() => {
        getUserAllData();
    }, []);

    const getUserAllData = async () => {
        try {
            const response = await profileSettingService.getUserProfileData(loginUserData?.id);
            const fetchedUserData = response?.data?.data;
            dispatch(setUserData(fetchedUserData));
            setGetUserData(fetchedUserData);
            setIsLoading(false);
        } catch (error) {
            console.error("API error:", error);
            setIsLoading(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <h3 className="d-lg-block d-none ">Profile Settings</h3>
            <div className="card-layout mt-4 profile-settings-section">
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="d-flex justify-content-between flex-wrap">
                            <ProfileImageUpdated getUserAllData={getUserAllData} getUserData={getUserData}
                                                 setIsLoading={setIsLoading}/>
                            <button
                                className="btn btn-primary-info py-2 px-3 height-max-content"
                                onClick={() => navigate(`/community/users/${userData?.id}`)}
                            >
                                View Public Profile
                            </button>
                        </div>
                        <div className="mt-4">
                            <ProfileSettingsForm getUserAllData={getUserAllData} getUserData={getUserData}
                                                 setIsLoading={setIsLoading}/>
                        </div>
                    </>
                )}
            </div>
            <div className="mt-4">
                <AchievementsListCard
                    achievements={userData?.badges}
                />
            </div>
            {userData?.next_steps.length > 0 &&
                <div className="mt-4">
                    <h3 className="d-lg-block">Next action steps</h3>
                    {userData?.next_steps.map((nextStep) => {
                        return (
                            <div className="text-white card px-5 py-4 cursor-pointer program-masterclass-card rounded-3"
                                 onClick={(event) => {
                                     event.stopPropagation();
                                     navigate("/programs/" + nextStep?.program?.id);
                                     scrollToTop();
                                 }}>
                                <div className="row no-gutters">
                                    <div className="col-md-4 d-flex align-items-center">
                                        <img src={nextStep?.program?.full_cover_image}
                                             className="card-img master-class-lesson__card-thumbnail img-fluid rounded-3"
                                             alt={nextStep?.program?.name}/>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="master-class-lesson__card__content">
                                        <span className="fs-4 lh-base fw-bolder">
                                            {nextStep?.program?.title}
                                        </span>
                                            <div className="program-card-description mt-3">
                                                {
                                                    nextStep?.step_description && renderAnswer(nextStep?.step_description)?.map((element, index) =>
                                                        <React.Fragment key={index}>{element}</React.Fragment>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    );
};

export default ProfileSettings;
