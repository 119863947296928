import React, {useEffect, useState} from "react";
import Loader from "../../components/common/Loader";
import notFound from "../../assets/not-found/not_found.png";
import {programsService} from "../../services/programsService/programsService";
import ProgramsList from "./components/ProgramsList";
import "./programs.css";
import ProgressBar from "@ramonak/react-progress-bar";
import useRenderTextEditorContent from "../../hooks/useTextEditorContent";
import {useNavigate} from "react-router-dom";

const Projects = ({ setIsPreviousSession, isPreviousSession }) => {
    const [programs, setPrograms] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const renderAnswer = useRenderTextEditorContent();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        getAllPrograms();
    }, []);

    const getAllPrograms = async () => {
        try {
            const response = await programsService.getPrograms();

            setPrograms(response.data['programs']);
            setRecommendations(response.data['recommendations']);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleNavigate = (id) => {
        navigate("/programs/" + id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="container-fluid">
                <h3 className="d-lg-block d-none">Programs</h3>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        {programs.length > 0 && (
                            <>
                                <ProgramsList programs={programs} />
                            </>
                        )}
                        {programs?.length === 0 && (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h3>No Programs Added</h3>
                                <p className="fs-5">No programs available at the moment.</p>
                            </div>
                        )}
                    </div>
                )}
                <h3 className="d-lg-block mt-3">Recommendations</h3>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        {recommendations.length > 0 && (
                            <>
                                <div className="row">
                                    {recommendations?.map((program) => {
                                        return (
                                            <React.Fragment key={program.id}>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 col-12">
                                                    <div
                                                        className={`card text-white m-3 p-4 program-masterclass-card rounded-3 ${
                                                            program?.is_completed ? "overlay-completed master-class-completed-opacity" : ""
                                                        }`} onClick={() => handleNavigate(program?.id)}>
                                                        <img src={program?.cover_image}
                                                             className="card-img-top program-masterclass-card-thumbnail img-fluid rounded-3 mb-3"
                                                             alt={program?.title}/>
                                                        <h5 className="card-title mb-3">{program?.title}</h5>
                                                        <div className="program-masterclass-card-description mb-3">
                                                            {
                                                                program?.description && renderAnswer(program?.description)?.map((element, index) =>
                                                                    <React.Fragment key={index}>{element}</React.Fragment>)
                                                            }
                                                        </div>
                                                        <ProgressBar completed={program.progress} bgColor="#1c64f2"
                                                                     baseBgColor="#374151"
                                                                     className="w-100 progressbar-count mt-2"/>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                        {recommendations?.length === 0 && (
                            <div
                                className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center"
                                style={{height: "500px"}}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300}/>
                                <h3>No Recommendations</h3>
                                <p className="fs-5">You don't have any recommendations at the moment.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Projects;