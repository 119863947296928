import React from "react";
import OtherLessonCard from "./OtherLessonCard";

const OtherLessonsList = ({ programTitle, lessons, selectedLessonId, withProgress = true }) => {

    const title = programTitle + ' Videos';

    return (
        <>
            <div className="other-lessons p-3">
                <div className="mb-3">
                    <span className="fs-5 lh-base fw-bolder">
                    {title}
                </span>
                </div>
                {lessons?.map((lesson) => {
                    return (
                        <React.Fragment key={lesson.id}>
                            <OtherLessonCard
                                lesson={lesson}
                                selectedLessonId={selectedLessonId}
                                withProgress={withProgress}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default OtherLessonsList;