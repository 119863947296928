import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Zendesk from "react-zendesk";
import AppRoutes from "./routes";
import "./App.css";
import {useEffect} from "react";
import usePusher from "./hooks/usePusher";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {incrementUnreadMessages} from "./redux/authSlice";

function App() {
    const pusher = usePusher();
    const userData = useSelector((state) => state?.auth?.userData);
    const dispatch = useDispatch();

    useEffect(() => {
        // window.zESettings = {
        //   webWidget: {
        //     chat: {
        //       suppress: false,
        //     },
        //   },
        // };
    }, []);

    const unlockAchievement = (badge) => {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={badge.logo}
                    alt="Achievement"
                    style={{ width: 50, height: 50, marginRight: 10 }}
                />
                <div>
                    <strong>Achievement Unlocked!</strong>
                    <p>{badge.name}</p>
                </div>
            </div>,
            {
                progressClassName: 'toastify-progress-custom',
            }
        );
    };

    useEffect(() => {
        if (pusher && userData) {
            const channel = pusher.subscribe('achievement-unlocked.' + userData.id);
            channel.bind('achievement-unlocked', function(data) {
                unlockAchievement(data.badge);
            });

            const newMessagesChannel = pusher.subscribe('message-received.' + userData.id);
            newMessagesChannel.bind('message-received', function(data) {
                dispatch(incrementUnreadMessages());
            });
        }
    }, [pusher]);

    return (
        <div className="App">
            <AppRoutes />
            {process.env?.REACT_APP_ZENDESK_KEY && <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} />}
        </div>
    );
}

export default App;
