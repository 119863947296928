/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import CatapultLogo from "../../../assets/catapult_mdl.png";
import ModalClose from "../../../assets/modal_close.png";
import PlayVideo from "../../../assets/Play_Circle.png";
import Frame from "../../../assets/frame.png";
import CloudUpload from "../../../assets/Cloud_Upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles/add-project.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProjectsService } from "../../../services/projectsService/ProjectsService";
import axios from "axios";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { toast } from "react-toastify";
import FileUploadedLoader from "../../../components/common/FileUploadedLoader";
import { MdDelete } from "react-icons/md";

const AddMoreProjects = ({ onClose, myProjectsData, mediaType }) => {
    // Start states
    const [coverImageObjectURL, setCoverImageObjectURL] = useState(null);
    const [contentImageObjectURLs, setContentImageObjectURLs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCoverLoading, setIsCoverLoading] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // End  states

    const validationSchema = Yup.object().shape({
        projectTitle: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .required("Project Title is required"),
        projectDescription: Yup.string()
            .required("Project Description is required")
            .test("is-non-empty-description", "Project Description is required", (value) => {
                const strippedValue = value.replace(/<\/?[^>]+(>|$)/g, "").trim();

                return strippedValue !== "" && strippedValue !== "<p><br></p>";
            }),
    });

    const handleClose = () => {
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            projectTitle: "",
            projectDescription: "",
            coverImage: "",
            contentImages: [],
        },
        validationSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            ProjectsService.addMyProjectsData({
                title: values.projectTitle,
                description: values.projectDescription,
                cover_image: values.coverImage,
                file_path: values.contentImages,
            })
                .then((res) => {
                    toast.success("Your project has been added successfully");
                    myProjectsData();
                    handleClose();
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
            formik.resetForm();
            onClose();
        },
    });

    const validateFileSize = (file) => {
        let imageMaxSizeInBytes;
        let videoMaxSixeInBytes;

        if (file.type.startsWith("image")) {
            imageMaxSizeInBytes = 10 * 1023 * 1023;
        } else if (file.type.startsWith("video")) {
            videoMaxSixeInBytes = 25 * 1024 * 1024;
        } else {
            toast.error("Please select a valid file format.");
            return false;
        }

        if (file.size > imageMaxSizeInBytes) {
            toast.error(`Image size must be less than or equal to ${imageMaxSizeInBytes / (1023 * 1023)}MB`);
            return false;
        } else if (file.size > videoMaxSixeInBytes) {
            toast.error(`Video size must be less than or equal to ${videoMaxSixeInBytes / (1024 * 1024)}MB`);
            return false;
        }

        return true;
    };

    const getPresignUrl = async (file_name) => {
        setIsCoverLoading(true);
        try {
            const response = await ProjectsService.getPresignUrl(file_name.name);

            if (response?.data?.status === true) {
                await axios.put(response?.data?.data?.url, file_name, {
                    headers: {
                        "Content-Type": file_name.type,
                        "x-amz-acl": "private",
                    },
                });
                formik.setFieldValue("coverImage", response?.data?.data?.file_name);
            }
            setIsCoverLoading(false);
        } catch (error) {
            console.error("Error getting pre-signed URL:", error);
            return null;
        }
    };

    // Function to get pre-signed URLs for content images
    const getContentImagePresignUrls = async (files) => {
        // const presignUrls = [];
        setIsLoading(true);
        try {
            for (const file of files) {
                const response = await ProjectsService.getPresignUrl(file.name);

                if (response?.data?.status === true) {
                    await axios.put(response?.data?.data?.url, file, {
                        headers: {
                            "Content-Type": file.type,
                            "x-amz-acl": "private",
                        },
                    });

                    const newFilePath = response?.data?.data?.file_name;
                    fileNames.push(newFilePath);
                }
            }
            formik.setFieldValue("contentImages", fileNames);
            setIsLoading(false);
        } catch (error) {
            console.error("Error getting pre-signed URLs for content images:", error);
        }
    };

    const checkContentType = async (url) => {
        return fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.headers.get("content-type");
                } else {
                    throw new Error("Failed to fetch");
                }
            })
            .then((contentType) => {
                if (contentType.startsWith("image")) {
                    return "image";
                } else if (contentType.startsWith("video")) {
                    return "video";
                } else {
                    return "unknown";
                }
            })
            .catch((error) => {
                console.error(error);
                return toast.error("invalid file Format");
            });
    };

    // Function to handle content image selection
    const handleContentImageChange = async (event) => {
        const files = Array.from(event.currentTarget.files);

        if (files.length > 15) {
            toast.error("You can only select up to 15 images or videos.");
            event.currentTarget.value = null;
            return;
        }

        if (files?.length > 0) {
            let isValid = true;

            for (const file of files) {
                if (!validateFileSize(file)) {
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                if (contentImageObjectURLs.length + files.length > 15) {
                    toast.error("You cannot upload more than 15 images or videos.");
                    event.currentTarget.value = null;
                    return;
                }

                await getContentImagePresignUrls(files);
                const objectURLs = await Promise.all(
                    files.map(async (file, index) => {
                        const url = URL.createObjectURL(file);

                        const contentType = await checkContentType(url);

                        mediaType?.push(contentType);
                        const newFilePath = fileNames[index];

                        setContentImageObjectURLs([...contentImageObjectURLs, { url, contentType, file_path: newFilePath }]);

                        return { url, contentType, file_path: newFilePath };
                    })
                );

                setContentImageObjectURLs([...contentImageObjectURLs, ...objectURLs]);
            } else {
                event.currentTarget.value = null;
            }
        }
    };

    const handleRemove = (file_path, url) => {
        fileNames.splice(
            fileNames.findIndex((item) => item === file_path),
            1
        );
        setContentImageObjectURLs((prevContentImageObjectURLs) => prevContentImageObjectURLs.filter((item) => item.url !== url));
    };

    const renderImageSlider = () => {
        const splideOptions = {
            rewind: true,
        };

        const contentImages = contentImageObjectURLs.map((item, index) => {
            return (
                <SplideSlide key={index}>
                    <div className="image-container">
                        {item.contentType === "image" ? (
                            <>
                                <figure className="image-display m-0">
                                    <img src={item.url} crossOrigin="anonymous" className="add-content-images" alt={`Selected Image`} />
                                </figure>
                                <div onClick={() => handleRemove(item?.file_path, item?.url)}>
                                    <MdDelete className="delete-icon" />
                                </div>
                            </>
                        ) : item.contentType === "video" ? (
                            <>
                                <figure className="image-display m-0">
                                    <video controls crossOrigin="anonymous" className="add-content-videos">
                                        <source src={item.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </figure>
                                <div onClick={() => handleRemove(item?.file_path, item?.url)}>
                                    <MdDelete className="delete-icon" />
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </SplideSlide>
            );
        });

        return (
            <div className={`add-projects-splide ${contentImages?.length > 1 ? "" : "hide-arrow"}`}>
                <Splide options={splideOptions} className="add-projects-splide">
                    {contentImages}
                </Splide>
            </div>
        );
    };

    // useEffect(() => {
    //     setIsSubmitting(!(formik.isValid && formik.dirty));
    // }, [formik.isValid, formik.dirty]);

    return (
        <Modal onHide={handleClose} show="true" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-90w" className="add-project-popup">
            <Modal.Header>
                <img src={CatapultLogo}></img>
                <div className="modal-close" onClick={() => handleClose()}>
                    <img src={ModalClose}></img>
                </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row w-100">
                        <div className="box col-sm-12 col-lg-12 col-md-12">
                            <Button variant="primary" type="submit" className="ml-30" disabled={isSubmitting}>
                                Make an Impact
                            </Button>
                        </div>
                        <div className="col-md-7 col-sm-12 col-lg-7 mt-4 pr-15">
                            <div>
                                <div className="box-2">
                                    <div className="question">
                                        Impact Title <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2">
                                        <input
                                            type="text"
                                            name="projectTitle"
                                            placeholder="Write your project title here"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.projectTitle}
                                            autoComplete="off"
                                            maxLength={50}
                                        />
                                        {formik.touched.projectTitle && formik.errors.projectTitle && <div className="error">{formik.errors.projectTitle}</div>}
                                    </div>
                                </div>
                                <div className="box-2 mt-4">
                                    <div className="question">Add Content</div>
                                    <div className="mt-4 w-ful box-3">
                                        {isLoading ? (
                                            <FileUploadedLoader />
                                        ) : (
                                            <div className="d-flex flex-column align-items-center">
                                                {contentImageObjectURLs?.length > 0 ? (
                                                    renderImageSlider()
                                                ) : (
                                                    <>
                                                        <img src={CloudUpload} alt="Upload Icon" width={100} height={100} className="add-project-upload-icon" />
                                                        <div className="text-center">
                                                            <div className="text-1 mt-1 mt-lg-3 mt-md-3 text-center">Select upto 15 Images/Videos</div>
                                                            <div className="text-2">Click 'Upload Image' or 'Upload Video' to add media</div>
                                                            <div className="text-2">Images: Max 10MB, Extensions: jpg, jpeg, png</div>
                                                            <div className="text-2">Videos: Max 25MB, Extensions: mp4, mpeg4, webm</div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="btn-container">
                                        <>
                                            <label htmlFor="imageContent" className="file-uploaded">
                                                <div className={`file-uploaded-btn ${isLoading ? "disabled" : ""}`}>
                                                    <img src={Frame} className="mr-5" alt="Image" />
                                                    Upload Image
                                                </div>
                                                <input
                                                    key={contentImageObjectURLs}
                                                    type="file"
                                                    id="imageContent"
                                                    accept=".jpg, .png, .jpeg"
                                                    style={{ display: "none" }}
                                                    multiple
                                                    onChange={handleContentImageChange}
                                                    disabled={isLoading}
                                                />
                                            </label>
                                            <label htmlFor="videoContent" className="file-uploaded">
                                                <div className={`file-uploaded-btn ${isLoading ? "disabled" : ""}`}>
                                                    <img src={PlayVideo} className="mr-5" alt="Video" />
                                                    Upload Video
                                                </div>
                                                <input
                                                    key={contentImageObjectURLs}
                                                    type="file"
                                                    id="videoContent"
                                                    accept=".mp4, .mpeg4, .webm"
                                                    style={{ display: "none" }}
                                                    multiple
                                                    onChange={handleContentImageChange}
                                                    disabled={isLoading}
                                                />
                                            </label>
                                        </>
                                    </div>
                                </div>

                                <div className="box-2 mt-4">
                                    <div className="question">
                                        Add Impact Description <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2">
                                        <ReactQuill
                                            theme="snow"
                                            value={formik.values.projectDescription}
                                            onChange={(value) => formik.setFieldValue("projectDescription", value)}
                                            onBlur={() => formik.setFieldTouched("projectDescription", true)}
                                        />
                                        {formik.touched.projectDescription && formik.errors.projectDescription && <div className="error">{formik.errors.projectDescription}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 col-lg-5">
                            <div>
                                <div className="box-2 mt-4">
                                    <div className="question">Cover Image</div>
                                    <div className="mt-4 w-ful mb-2 box-3" id="fileUploadDiv">
                                        <div>
                                            {coverImageObjectURL ? (
                                                <>
                                                    <div className="image-container">
                                                        {isCoverLoading ? (
                                                            <FileUploadedLoader />
                                                        ) : (
                                                            <>
                                                                <figure className="image-display m-0">
                                                                    <img src={coverImageObjectURL} crossOrigin="anonymous" className="add-content-images" alt={`Selected Image`} />
                                                                </figure>
                                                                <div
                                                                    onClick={() => {
                                                                        formik.setFieldValue("coverImage", null);
                                                                        setCoverImageObjectURL(null);
                                                                    }}
                                                                >
                                                                    <MdDelete className="delete-icon" />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <label htmlFor="fileInput" className="file-uploaded">
                                                        <img crossOrigin="anonymous" src={CloudUpload} alt="Upload Icon" />
                                                        <div className="text-1 mt-3 text-center">Select image here</div>
                                                        <div className="text-2">Images: Max 10MB, extensions: jpg, jpeg, png</div>
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <input
                                        key={coverImageObjectURL}
                                        type="file"
                                        id="fileInput"
                                        accept=".jpg, .png, .jpeg"
                                        style={{ display: "none" }}
                                        onChange={async (event) => {
                                            const file = event.currentTarget.files[0];

                                            if (file) {
                                                // Check if the file format is valid
                                                const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
                                                if (!allowedFormats.includes(file.type)) {
                                                    toast.error("Please select a valid image format (jpg, jpeg, or png).");
                                                    event.currentTarget.value = null;
                                                    return;
                                                }

                                                if (!validateFileSize(file)) {
                                                    event.currentTarget.value = null;
                                                } else {
                                                    try {
                                                        const presignUrl = getPresignUrl(file);

                                                        if (presignUrl) {
                                                            const objectURL = URL.createObjectURL(file);
                                                            setCoverImageObjectURL(objectURL);
                                                        } else {
                                                            console.error("Failed to fetch pre-signed URL.");
                                                        }
                                                    } catch (error) {
                                                        console.error("Error creating Object URL or fetching pre-signed URL:", error);
                                                    }
                                                }
                                            }
                                        }}
                                    />

                                    {formik.touched.coverImage && formik.errors.coverImage && <div className="error">{formik.errors.coverImage}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddMoreProjects;
