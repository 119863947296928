import React, {useEffect, useRef, useState} from "react";
import {CommentsService} from "../../services/commentsService/commentsService";
import Comment from "./Comment";
import {toast} from "react-toastify";
import CommentLoader from "../common/CommentLoader";
import CommentReplyModal from "./CommentReplyModal";
import EditCommentModal from "./EditCommentModal";
import Swal from "sweetalert2";

const DiscussionsCommentList = ({ path, title = 'Discussion', additionalClasses = '', height = 800, relatedTitlesToDisplay, highlightingCompareId = null }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [nextCursor, setNextCursor] = useState(null);
    const [formComment, setFormComment] = useState('');
    const commentsRef = useRef(null);

    const [showReplyCommentModal, setShowReplyCommentModal] = useState(false);
    const [showUpdateCommentModal, setShowUpdateCommentModal] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);

    const discussionCommentsData = async () => {
        setIsLoading(true);
        setNextCursor(null);
        try {
            const response = await CommentsService.getComments(path, nextCursor);

            if (nextCursor) {
                setComments((prevComments) => [...prevComments, ...response.data.data]);
            }
            if (!nextCursor) {
                setComments(response.data.data);
            }

            setNextCursor(response.data.meta.next_cursor);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        discussionCommentsData();
    }, []);

    const handleNewCommentBodyChange = (e) => {
        setFormComment(e.target.value);
    };

    const handleAddComment = async (e) => {
        e.preventDefault();

        try {
            const response = await CommentsService.addComment(path, {
                body: formComment,
            });

            const newComment = response.data;

            setComments((prevComments) => {
                return [newComment, ...prevComments];
            });

            setFormComment('');
            toast.success('Comment added successfully!');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to add comment');
        }
    };

    const handleDeleteConfirmation = (comment) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this comment?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(comment.id);
            }
        });
    };

    const handleDelete = async (commentId) => {
        try {
            await CommentsService.deleteComment(path, commentId);

            const deleteCommentRecursively = (comments, idToDelete) =>
                comments
                    .filter((comment) => comment.id !== idToDelete)
                    .map((comment) => ({
                        ...comment,
                        replies: deleteCommentRecursively(comment.replies || [], idToDelete),
                    }));

            setComments((prevComments) => deleteCommentRecursively(prevComments, commentId));

            toast.success('Comment deleted successfully!');
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to delete comment');
        }
    };

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = commentsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 20) {
            if (nextCursor) {
                discussionCommentsData(nextCursor);
            }
        }
    };

    useEffect(() => {
        const commentsListElement = commentsRef.current;
        if (commentsListElement) {
            commentsListElement.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (commentsListElement) {
                commentsListElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [nextCursor]);

    const handleReplyClick = (comment) => {
        setSelectedComment(comment);
        setShowReplyCommentModal(true);
    };

    const handleRepliesUpdate = (commentId, newReply) => {
        setComments((prevComments) =>
            updateReplies(prevComments, commentId, newReply)
        );
    };

    const updateReplies = (comments, commentId, newReply) => {
        return comments.map((comment) => {
            if (comment.id === commentId) {
                return {
                    ...comment,
                    replies: [newReply, ...(comment.replies || [])],
                };
            }

            if (comment.replies?.length) {
                return {
                    ...comment,
                    replies: updateReplies(comment.replies, commentId, newReply),
                };
            }

            return comment;
        });
    };

    const updateComment = (updatedComment) => {
        const updateCommentsRecursively = (comments, updated) =>
            comments.map((comment) =>
                comment.id === updated.id
                    ? { ...comment, ...updated }
                    : {
                        ...comment,
                        replies: updateCommentsRecursively(comment.replies || [], updated),
                    }
            );

        setComments((prevComments) => updateCommentsRecursively(prevComments, updatedComment));
    };

    return (
        <div className={`${additionalClasses}`}>
            <div className="mb-1">
                        <span className="fs-5 lh-base fw-bolder">
                            {title}
                        </span>
            </div>
            <form onSubmit={handleAddComment}>
                <div className="comment__message-form">
                            <textarea
                                value={formComment}
                                onChange={handleNewCommentBodyChange}
                                placeholder="Write a comment..."
                                className="w-ful py-2"
                                rows={4}>
                            </textarea>
                </div>
                <div className="d-flex justify-content-end mt-2">
                    <button type="submit" className="btn btn-primary">
                        Post comment
                    </button>
                </div>
            </form>
            <div
                className="comments-list mt-2"
                style={{height: height}}
                ref={commentsRef}
            >
                {comments?.map((comment) => (
                    <React.Fragment key={comment.id}>
                        <Comment
                            comment={comment}
                            relatedTitlesToDisplay={relatedTitlesToDisplay}
                            path={path}
                            onReplyClick={handleReplyClick}
                            onUpdateComment={updateComment}
                            highlightingCompareId={highlightingCompareId}
                            onEditCommentClick={(comment) => {
                                setSelectedComment(comment);
                                setShowUpdateCommentModal(true);
                            }}
                            onDeleteCommentClick={handleDeleteConfirmation}
                        />
                    </React.Fragment>
                ))}
                {isLoading &&
                    <CommentLoader/>
                }
            </div>
            <CommentReplyModal
                commentId={selectedComment?.id}
                show={showReplyCommentModal}
                handleClosePopup={() => {
                    setSelectedComment(null);
                    setShowReplyCommentModal(false);
                }}
                path={path}
                onAddReply={(newReply) => handleRepliesUpdate(selectedComment.id, newReply)}
            />
            <EditCommentModal
                comment={selectedComment}
                show={showUpdateCommentModal}
                handleClosePopup={() => {
                    setSelectedComment(null);
                    setShowUpdateCommentModal(false);
                }}
                path={path}
                onUpdateComment={updateComment}
            />
        </div>
    );
};

export default DiscussionsCommentList;