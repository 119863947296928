import Select, { components } from "react-select";
import React, {useEffect, useState} from "react";
import {tagService} from "../../../services/tagService/TagService";
import "../Style/TagSelector.css";

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props}>
            <span>No skills available</span>
        </components.NoOptionsMessage>
    );
};

export default function TagSelector({ tags, setTags }) {
    const [allTags, setAllTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const allTagsData = () => {
        setIsLoading(true);
        tagService.getTag().then((response) => {
            setAllTags(response?.data?.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        allTagsData();
    }, []);

    const options = allTags.map((option, idx) => ({
        value: option.id,
        label: option.name,
    }));

    const onChange = (selectedOptions) => {
        setTags(selectedOptions);
    };

    return (
        <div className="mb-4">
            <label htmlFor="email" className="form-label mb-2">
                Skills
            </label>
            <Select
                name="tags"
                isLoading={isLoading}
                options={options}
                value={tags}
                onChange={onChange}
                isMulti
                placeholder="Please select the skills that suit you"
                components={{ NoOptionsMessage }}
            />
        </div>
    );
}