import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import ModalClose from "../../../../assets/modal_close.png";
import {Rating} from "@mui/material";
import {programsService} from "../../../../services/programsService/programsService";
import Swal from "sweetalert2";
import PdfPreview from "../../../../components/PdfPreview/PdfPreview";

const ProgramCompleteInfoModal = ({ handleClose, program, onUpdateProgram }) => {

    const [rating, setRating] = useState(program?.rating);

    const handleRateProgram = async (ratingValue) => {
        try {
            setRating(ratingValue);
            const updatedProgram = await programsService.rateProgram(program.id, ratingValue);
            onUpdateProgram(updatedProgram.data);
            Swal.fire("Completed!", "Program has been rated.", "success");
        } catch (err) {
            Swal.fire("Error!", "Failed to rate the program.", "error");
        }
    };

    return (
        <>
            <Modal onHide={handleClose} centered className="resources-file-uploaded-popup" show="true" size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="modal-header m-0">
                    <div className="question">You've completed {program?.name}</div>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose} alt="Close" />
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{maxHeight: "450px", overflowY: "scroll"}}
                >
                    <div className="row">
                        <PdfPreview fileUrl={program?.certificate} />
                    </div>
                    <div className="row mt-3 px-3">
                        {program?.badges.length !== 0 &&
                            <div className="box col-sm-12 col-lg-12 col-md-12 flex-column gap-3 align-items-center">
                                <div className="question">
                                    Achievement Unlocked!
                                </div>
                                <div className="d-flex justify-content-center flex-wrap">
                                    {program?.badges.map((achievement, index) => {
                                        return <div className="text-center user-profile-achievements__item" key={index}>
                                            <img className="user-profile-achievements__item__logo"
                                                 src={achievement.logo}/>
                                            <span>{achievement.name}</span>
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                        <div className="d-flex mt-3">
                            <a target="_blank" href={program.survey_url}>
                                <button className="btn btn-primary">Complete Survey</button>
                            </a>
                        </div>
                        <div className="mt-3 rating-title">
                            Rate this program:
                        </div>
                        <Rating value={rating} onChange={(event, value) => handleRateProgram(value)}/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProgramCompleteInfoModal;