import {useNavigate} from "react-router-dom";
import React from "react";

const DashboardMasterclassCard = ({ masterClass }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/master-classes/" + masterClass.id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <div className="card text-white m-3 p-4 program-masterclass-card rounded-3" onClick={handleNavigate}>
                    <img src={masterClass?.cover_image}
                         className="card-img-top program-masterclass-card-thumbnail img-fluid rounded-3 mb-3"
                         alt={masterClass?.name?.name}/>
                    <h5 className="card-title mb-2">{masterClass?.name}</h5>
                </div>
            </div>
        </>
    );
};

export default DashboardMasterclassCard;