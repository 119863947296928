import apiClient from "../../utils/apiClient";
import apiService from "../apiService/apiService";

const getSingleUserData = (id) => {
    const url = `community/users/${id}`;
    return apiClient.get(url);
};

const getOnlineUsers = (perPage, page, searchTerm) => {
    const params = {
        'perPage': perPage,
        's': searchTerm,
        'page': page
    };

    const queryString = apiService.getRequestParams(params);

    const url = `community/online-users${queryString ? '?' + queryString : ''}`;
    return apiClient.get(url);
};

export const userService = {
    getSingleUserData,
    getOnlineUsers
};