import {RotatingLines} from "react-loader-spinner";
import React from "react";

const CommentLoader = () => {
    return (
        <>
            <div className="comments-loader-container ">
                <RotatingLines
                    height={35}
                    width={35}
                    color="green"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                    strokeColor="grey"
                    animationDuration="0.75"
                />
            </div>
        </>
    );
};

export default CommentLoader;