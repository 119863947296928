import React, { useState, useEffect } from "react";
import {IoDocumentTextOutline, IoGrid, IoPieChart, IoSettingsOutline} from "react-icons/io5";
import { LiaChartPieSolid, LiaDownloadSolid } from "react-icons/lia";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/authSlice";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";
import { GoHome } from "react-icons/go";
import { FiAirplay, FiBookOpen } from "react-icons/fi";
import {FaRegFileAlt, FaUserFriends} from "react-icons/fa";
import { LuCalendarDays, LuFileEdit } from "react-icons/lu";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { BsPatchCheck } from "react-icons/bs";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { PiCaretDownBold, PiCaretRightBold, PiCaretUpBold } from "react-icons/pi";
import { TfiFiles } from "react-icons/tfi";
import { TbLogout } from "react-icons/tb";
import { TbCalendarStar } from "react-icons/tb";

const Asidebar = ({ isLoading }) => {
    // states start
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [active, setActive] = useState("");
    const dispatch = useDispatch();
    const [isProjectsDropdownOpen, setIsProjectsDropdownOpen] = useState(false);
    const [submenuActive1, setSubmenuActive1] = useState(false);
    const [submenuActive2, setSubmenuActive2] = useState(false);
    const [isCommunityDropdownOpen, setIsCommunityDropdownOpen] = useState(false);
    const [submenuActive3, setSubmenuActive3] = useState(false);
    const [submenuActive4, setSubmenuActive4] = useState(false);
    // states end

    // Function to toggle the Projects dropdown
    const toggleProjectsDropdown = () => {
        setIsProjectsDropdownOpen(!isProjectsDropdownOpen);
    };

    const toggleSubmenu1 = () => {
        setSubmenuActive1(true);
        setSubmenuActive2(false);
    };
    const toggleSubmenu2 = () => {
        setSubmenuActive2(true);
        setSubmenuActive1(false);
    };

    const toggleCommunityDropdown = () => {
        setIsCommunityDropdownOpen(!isCommunityDropdownOpen);
    };
    const toggleSubmenu3 = () => {
        setSubmenuActive3(true);
        setSubmenuActive4(false);
        setIsCommunityDropdownOpen(true);
    };
    const toggleSubmenu4 = () => {
        setSubmenuActive4(true);
        setSubmenuActive3(false);
        setIsCommunityDropdownOpen(true);
    };

    useEffect(() => {
        const activeTab = location.pathname.split("/")[1];
        setActive(activeTab);
        if (activeTab === "impacts") {
            setIsProjectsDropdownOpen(true);
        } else {
            setIsProjectsDropdownOpen(false);
        }
        if (activeTab === "community") {
            setIsCommunityDropdownOpen(true);
        } else {
            setIsCommunityDropdownOpen(false);
        }
    }, [location]);

    const userType = useSelector((state) => state?.auth?.userData?.user_type);

    // function of logout
    const handleLogout = () => {
        dispatch(logout());
        sessionStorage.clear();
        localStorage.clear();
        setActive("Logout");
        navigate("/");
        toast.success("Logged out successfully");
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <ul className="ms-2 left-navbar">
                        {/* sidebar top items start */}
                        <div>
                            <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                className={`py-3 ps-4 pe-5 ${active === "dashboard" && "leftSidebar-active"}`}
                                onClick={() => {
                                    setActive("dashbpard");
                                    navigate("/dashboard");
                                    scrollToTop();
                                }}
                            >
                                <IoPieChart
                                    className={`fs-5 icon-bg ${active === "dashboard" ? "icon-active" : "icon-inactive"}`}/>
                                <span className="ms-3">Dashboard</span>
                            </li>
                            {userType === 4 && (
                                <>
                                    <li
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        className={`py-3 ps-4 pe-5 ${active === "master-classes" && "leftSidebar-active"}`}
                                        onClick={() => {
                                            setActive("master-classes");
                                            navigate("/master-classes");
                                            scrollToTop();
                                        }}
                                    >
                                        <IoGrid
                                            className={`fs-5 icon-bg ${active === "keynotes" ? "icon-active" : "icon-inactive"}`}/>
                                        <span className="ms-3">Masterclasses</span>
                                    </li>
                                </>
                            )}
                            {userType !== 4 && (
                                <>
                                    <li
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        className={`py-3 ps-4 pe-5 ${active === "programs" && "leftSidebar-active"}`}
                                        onClick={() => {
                                            setActive("programs");
                                            navigate("/programs");
                                            scrollToTop();
                                        }}
                                    >
                                        <IoGrid
                                            className={`fs-5 icon-bg ${active === "keynotes" ? "icon-active" : "icon-inactive"}`}/>
                                        <span className="ms-3">Programs</span>
                                    </li>

                                    <li
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        className={`py-3 ps-4 pe-5 ${active === "master-classes" && "leftSidebar-active"}`}
                                        onClick={() => {
                                            setActive("master-classes");
                                            navigate("/master-classes");
                                            scrollToTop();
                                        }}
                                    >
                                        <IoGrid
                                            className={`fs-5 icon-bg ${active === "keynotes" ? "icon-active" : "icon-inactive"}`}/>
                                        <span className="ms-3">Masterclasses</span>
                                    </li>

                                    {/*<li*/}
                                    {/*    data-bs-dismiss="offcanvas"*/}
                                    {/*    aria-label="Close"*/}
                                    {/*    className={`py-3 ps-4 pe-5 ${active === "playbook" && "leftSidebar-active"}`}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        setActive("playbook");*/}
                                    {/*        navigate("/playbook");*/}
                                    {/*        scrollToTop();*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <FiBookOpen*/}
                                    {/*        className={`fs-5 icon-bg ${active === "playbook" ? "icon-active" : "icon-inactive"}`}/>*/}
                                    {/*    <span className="ms-3">Playbook</span>*/}
                                    {/*</li>*/}
                                </>
                            )}
                            {userType !== 4 && (
                                <>
                                    <li
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        className={`py-3 ps-4 pe-5 ${active === "live-events" && "leftSidebar-active"}`}
                                        onClick={() => {
                                            setActive("live-events");
                                            navigate("/live-events");
                                            scrollToTop();
                                        }}
                                    >
                                        <TbCalendarStar
                                            className={`fs-5 icon-bg ${active === "live-events" ? "icon-active" : "icon-inactive"}`}/>
                                        <span className="ms-3">Live Events</span>
                                    </li>
                                </>
                            )}
                            <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                className={`py-3 ps-4 pe-5 ${active === "community" && "leftSidebar-active"}`}
                                onClick={() => {
                                    setActive("community");
                                    navigate("/community");
                                    scrollToTop();
                                }}
                            >
                                <FaUserFriends
                                    className={`fs-5 icon-bg ${active === "community" ? "icon-active" : "icon-inactive"}`}/>
                                <span className="ms-3">Community</span>
                            </li>
                            {/*<li*/}
                            {/*    data-bs-dismiss="offcanvas"*/}
                            {/*    aria-label="Close"*/}
                            {/*    className={`py-3 ps-4 pe-5 ${active === "calendar" && "leftSidebar-active"}`}*/}
                            {/*    onClick={() => {*/}
                            {/*        setActive("calendar");*/}
                            {/*        navigate("/calendar");*/}
                            {/*        scrollToTop();*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <LuCalendarDays className={`fs-5 icon-bg ${active === "calendar" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*    <span className="ms-3">Calendar</span>*/}
                            {/*</li>*/}
                            {/*{userType === 4 && (*/}
                            {/*    <>*/}
                            {/*        <li*/}
                            {/*            data-bs-dismiss="offcanvas"*/}
                            {/*            aria-label="Close"*/}
                            {/*            className={`py-3 ps-4 pe-5 ${active === "recorded-sessions" && "leftSidebar-active"}`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setActive("recorded-sessions");*/}
                            {/*                navigate("/recorded-sessions");*/}
                            {/*                scrollToTop();*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <FiAirplay className={`fs-5 icon-bg ${active === "recorded-sessions" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*            <span className="ms-3">Recorded Sessions</span>*/}
                            {/*        </li>*/}
                            {/*    </>*/}
                            {/*)}*/}

                            {/*{userType !== 4 && (*/}
                            {/*    <>*/}
                            {/*        <li*/}
                            {/*            data-bs-dismiss="offcanvas"*/}
                            {/*            className={`py-3 ps-4 pe-4 d-flex justify-content-between  ${active === "impacts" && "leftSidebar-active"}`}*/}
                            {/*            onClick={() => {*/}
                            {/*                toggleProjectsDropdown();*/}
                            {/*                setActive("impacts");*/}
                            {/*                scrollToTop();*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <div>*/}
                            {/*                <FaRegFileAlt className={`fs-5 icon-bg ${active === "impacts" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*                <span className="ms-3">Impacts</span>*/}
                            {/*            </div>*/}
                            {/*            {isProjectsDropdownOpen ? (*/}
                            {/*                <div className="discussion-toggle">*/}
                            {/*                    <PiCaretUpBold className="mt-1 fs-6" />*/}
                            {/*                </div>*/}
                            {/*            ) : (*/}
                            {/*                <div className="discussion-toggle">*/}
                            {/*                    <PiCaretDownBold className="mt-1 fs-6" />*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </li>*/}

                            {/*        {isProjectsDropdownOpen && (*/}
                            {/*            <ul className={`submenu `}>*/}
                            {/*                <li*/}
                            {/*                    data-bs-dismiss="offcanvas"*/}
                            {/*                    className={`py-3 ps-4 pe-4 d-flex justify-content-between`}*/}
                            {/*                    onClick={() => {*/}
                            {/*                        toggleSubmenu1();*/}
                            {/*                        navigate("/impacts/all-impacts");*/}
                            {/*                        scrollToTop();*/}
                            {/*                    }}*/}
                            {/*                >*/}
                            {/*                    <div>*/}
                            {/*                        <TfiFiles className={`fs-5 icon-bg ${active === "impacts/all-impacts" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*                        <span className="ms-3">Impacts</span>*/}
                            {/*                    </div>*/}
                            {/*                    {submenuActive1 && (*/}
                            {/*                        <>*/}
                            {/*                            <div className="discussion-toggle">*/}
                            {/*                                <PiCaretRightBold className="mt-1 fs-6" />*/}
                            {/*                            </div>*/}
                            {/*                        </>*/}
                            {/*                    )}*/}
                            {/*                </li>*/}
                            {/*                {userType !== 4 && (*/}
                            {/*                    <>*/}
                            {/*                        <li*/}
                            {/*                            data-bs-dismiss="offcanvas"*/}
                            {/*                            className={`py-3 ps-4 pe-4 d-flex justify-content-between`}*/}
                            {/*                            onClick={() => {*/}
                            {/*                                toggleSubmenu2();*/}
                            {/*                                navigate("/impacts/my-impacts");*/}
                            {/*                                scrollToTop();*/}
                            {/*                            }}*/}
                            {/*                        >*/}
                            {/*                            <div>*/}
                            {/*                                <LuFileEdit className={`fs-5 icon-bg ${active === "impacts/my-impacts" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*                                <span className="ms-3">My Impact</span>*/}
                            {/*                            </div>*/}
                            {/*                            {submenuActive2 && (*/}
                            {/*                                <>*/}
                            {/*                                    <div className="discussion-toggle">*/}
                            {/*                                        <PiCaretRightBold className="mt-1 fs-6" />*/}
                            {/*                                    </div>*/}
                            {/*                                </>*/}
                            {/*                            )}*/}
                            {/*                        </li>*/}
                            {/*                    </>*/}
                            {/*                )}*/}
                            {/*            </ul>*/}
                            {/*        )}*/}
                            {/*    </>*/}
                            {/*)}*/}

                            {/*<li*/}
                            {/*    data-bs-dismiss="offcanvas"*/}
                            {/*    className={`py-3 ps-4 pe-4 d-flex justify-content-between  ${active === "community" && "leftSidebar-active"}`}*/}
                            {/*    onClick={() => {*/}
                            {/*        toggleCommunityDropdown();*/}
                            {/*        setActive("community");*/}
                            {/*        scrollToTop();*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <div>*/}
                            {/*        <HiOutlineUserGroup className={`fs-5 icon-bg `} />*/}
                            {/*        <span className="ms-3">Community</span>*/}
                            {/*    </div>*/}
                            {/*    {isCommunityDropdownOpen ? (*/}
                            {/*        <div className="discussion-toggle">*/}
                            {/*            <PiCaretUpBold className="mt-1 fs-6" />*/}
                            {/*        </div>*/}
                            {/*    ) : (*/}
                            {/*        <div className="discussion-toggle">*/}
                            {/*            <PiCaretDownBold className="mt-1 fs-6" />*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</li>*/}

                            {/*{isCommunityDropdownOpen && (*/}
                            {/*    <ul className={`submenu `}>*/}
                            {/*        <li*/}
                            {/*            data-bs-dismiss="offcanvas"*/}
                            {/*            className={`py-3 ps-4 pe-4 d-flex justify-content-between`}*/}
                            {/*            onClick={() => {*/}
                            {/*                toggleSubmenu3();*/}
                            {/*                setActive("community");*/}
                            {/*                navigate("/community/general-discussion");*/}
                            {/*                scrollToTop();*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <div>*/}
                            {/*                <TfiFiles className={`fs-5 icon-bg ${active === "community" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*                <span className="ms-3">General Discussion</span>*/}
                            {/*            </div>*/}
                            {/*            {submenuActive3 && (*/}
                            {/*                <>*/}
                            {/*                    <div className="discussion-toggle">*/}
                            {/*                        <PiCaretRightBold className="mt-1 fs-6" />*/}
                            {/*                    </div>*/}
                            {/*                </>*/}
                            {/*            )}*/}
                            {/*        </li>*/}
                            {/*        <li*/}
                            {/*            data-bs-dismiss="offcanvas"*/}
                            {/*            className={`py-3 ps-4 pe-4 d-flex justify-content-between`}*/}
                            {/*            onClick={() => {*/}
                            {/*                toggleSubmenu4();*/}
                            {/*                setActive("community");*/}
                            {/*                navigate("/community/keynote-discussion");*/}
                            {/*                scrollToTop();*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <div>*/}
                            {/*                <LuFileEdit className={`fs-5 icon-bg ${active === "community" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*                <span className="ms-3">Keynote Discussion</span>*/}
                            {/*            </div>*/}
                            {/*            {submenuActive4 && (*/}
                            {/*                <>*/}
                            {/*                    <div className="discussion-toggle">*/}
                            {/*                        <PiCaretRightBold className="mt-1 fs-6" />*/}
                            {/*                    </div>*/}
                            {/*                </>*/}
                            {/*            )}*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*)}*/}

                            {/*{userType !== 4 && (*/}
                            {/*    <>*/}
                            {/*        <li*/}
                            {/*            data-bs-dismiss="offcanvas"*/}
                            {/*            aria-label="Close"*/}
                            {/*            className={`py-3 ps-4 pe-5 ${active === "certificate" && "leftSidebar-active"}`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setActive("certificate");*/}
                            {/*                navigate("/certificate");*/}
                            {/*                scrollToTop();*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <BsPatchCheck className={`fs-5 icon-bg ${active === "certificate" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*            <span className="ms-3">My Certificate</span>*/}
                            {/*        </li>*/}
                            {/*    </>*/}
                            {/*)}*/}

                            {/*{userType === 3 && (*/}
                            {/*    <li*/}
                            {/*        data-bs-dismiss="offcanvas"*/}
                            {/*        aria-label="Close"*/}
                            {/*        className={`py-3 ps-4 pe-5 ${active === "reports" && "leftSidebar-active"}`}*/}
                            {/*        onClick={() => {*/}
                            {/*            setActive("reports");*/}
                            {/*            navigate("/reports");*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <LiaChartPieSolid className={`fs-5 icon-bg ${active === "reports" ? "icon-active" : "icon-inactive"}`} />*/}
                            {/*        <span className="ms-3">Reports</span>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                        </div>
                        {/* sidebar top items end */}
                        {/* sidebar middle items start */}
                        <div className="siderbar-middle-item-top">
                            <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                className={`py-3 ps-4 pe-5 ${active === "profile-settings" && "leftSidebar-active"}`}
                                onClick={() => {
                                    setActive("profile-settings");
                                    navigate("/profile-settings");
                                    scrollToTop();
                                }}
                            >
                                <IoSettingsOutline
                                    className={`fs-5 icon-bg ${active === "profile-settings" ? "icon-active" : "icon-inactive"}`}/>
                                <span className="ms-4">{t("ASIDEBAR.SETTINGS")}</span>
                            </li>

                            <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                className={`py-3 ps-4 pe-5 ${active === "technical-assistant" && "leftSidebar-active"}`}
                                onClick={() => {
                                    setActive("technical-assistant");
                                    navigate("/technical-assistant");
                                    scrollToTop();
                                }}
                            >
                                <AiOutlineExclamationCircle className={`fs-5 icon-bg ${active === "technical-assistant" ? "icon-active" : "icon-inactive"}`} />
                                <span className="ms-4">FAQ</span>
                            </li>
                            <li data-bs-dismiss="offcanvas" aria-label="Close" className={`py-3 ps-4 pe-5 ${active === "Logout" && "leftSidebar-active"}`} onClick={handleLogout}>
                                <TbLogout className="fs-5 icon-bg" />
                                <span className="ms-4">{t("ASIDEBAR.LOGOUT")}</span>
                            </li>
                        </div>
                        {/* sidebar middle items end */}
                        {/* sidebar end items start */}
                        {/* <div>
                            <li
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                className={`py-3 ps-4 pe-5 ${active === "download-app" && "leftSidebar-active"}`}
                                onClick={() => {
                                    setActive("download-app");
                                    navigate("download-app");
                                    scrollToTop();
                                }}
                            >
                                <LiaDownloadSolid className={`fs-5 icon-bg ${active === "download-app" ? "icon-active" : "icon-inactive"}`} />
                                <span className="ms-4">{t("ASIDEBAR.DOWNLOAD.APP")}</span>
                            </li>
                        </div> */}
                        {/* sidebar end items end */}
                    </ul>
                </>
            )}
        </>
    );
};

export default Asidebar;
