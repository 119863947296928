import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import ModalClose from "../../../../assets/modal_close.png";
import ReactQuill from "react-quill";
import Button from "react-bootstrap/Button";
import {programsService} from "../../../../services/programsService/programsService";
import {toast} from "react-toastify";

const NextStepsModal = ({ programId, onUpdateProgram, onClose, showModal, initialValue = null, nextStepId = null }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resourceDescription, setResourceDescription] = useState(initialValue);

    const handleClose = () => {
        onClose();
    };

    const handleCreate = async () => {
        setIsSubmitting(true);
        try {
            const updatedProgram = nextStepId
                ? await programsService.updateNextSteps(programId, resourceDescription, nextStepId)
                : await programsService.createNextSteps(programId, resourceDescription);
            onUpdateProgram(updatedProgram.data);
            if (!nextStepId) {
                toast.success("Next steps added successfully");
            }
            if (nextStepId) {
                toast.success("Next steps updated successfully");
            }
            onClose();
        } catch (error) {
            toast.error(error?.response?.data?.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!showModal) {
        return <></>;
    }

    return (
        <>
            <Modal onHide={handleClose} centered className="resources-file-uploaded-popup" show="true" size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="modal-header m-0">
                    <div className="question">Create Your Next Steps</div>
                    <div className="modal-close" onClick={() => handleClose()}>
                        <img src={ModalClose} alt="Close" />
                    </div>
                </Modal.Header>
                <form className={`form ${isSubmitting ? "form-not-clickable" : ""}   `}>
                    <Modal.Body
                        style={{ maxHeight: "450px", overflowY: "scroll" }}
                        className={`modal-body `}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDragEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div className="dropzone">
                            <div className="box-2">
                                <div className="question">
                                    What is one tactic, idea, or action you plan to implement as a next step as a result of this program?
                                </div>
                                <div className="mt-4 w-ful mb-2">
                                    <ReactQuill
                                        theme="snow"
                                        value={resourceDescription}
                                        onChange={(value) => setResourceDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn btn-primary add-btn"
                            onClick={handleCreate}
                            disabled={isSubmitting}
                        >
                            Save to Profile
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default NextStepsModal;